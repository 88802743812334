.sideMenu .background {
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    position: fixed;
}

.sideMenu.opened .background {
    background: rgba(50, 50, 50, 0.5);
    animation: openModal .3s ease;
    display: block;
}

.sideMenu.closed .background {
    background: none;
    animation: closeModal .3s ease;
    display: none;
}

@keyframes openModal {
    0% {
        display: block;
        background: none;
    }
    100% {
        background: rgba(50, 50, 50, 0.5);
        display: none;
    }
}

@keyframes closeModal {
    0% {
        background: rgba(50, 50, 50, 0.5);
        display: block;
    }
    100% {
        display: none;
        background: none;
    }
}

.sideMenu .container {
    position: fixed;
    top: 0;
    right: 0;
    width: 500px;
    margin-right: -500px;
    background: #fff;
    /*background: #5c47b9;*/
    height: 100%;
    z-index: 200;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    transition: margin-right .3s ease;
    display: flex;
    flex-flow: column;
    max-width: 90%;
}

.sideMenu.closed * {
    box-shadow: none!important;
}

.sideMenu.opened .container {
    margin-right: 0;
}
.sideMenu .container .header {
    padding: 15px;
    flex: 0 1 25px;
    /*box-shadow: 0 2px 10px rgba(0, 0, 0, .2);*/
}

.sideMenu .close {
    position: absolute;
    /*top: 10px;*/
    /*right: 10px;*/
    top: 14px;
    right: 14px;
    font-size: 26px;
    /*color: #fff;*/
    cursor: pointer;
}

.sideMenu .container h3 {
    /*color: #5372e4;*/
    /*color: #fff;*/
    font-weight: 500;
    text-align: center;
    /*border-bottom: 1px solid #7048e8;*/
    /*border-bottom: 1px solid rgba(255, 255, 255, 0.5);*/
    width: 90%;
    margin: 0 auto;
}

.sideMenu .container ul.menu {
    text-align: left;
    list-style: none;
    width: 90%;
    margin: 0 auto;
    padding: 0;
}

.sideMenu .container ul.menu li {
    /*color: #fff;*/
    padding: 5px 0;
    font-size: 16px;
    font-weight: 300;
}
.sideMenu .uppercase {
    text-transform: uppercase;
}
.sideMenu .goToBonjour {
    cursor: pointer;
}
.sideMenu .container ul.menu li i {
    margin-right: 15px;
}

.sideMenu .container ul.menu.authentication {
    width: 100%;
    padding-left: 25px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    display: block;
    flex: 0 1 55px;
}

.sideMenu .container ul.menu.authentication li {
    cursor: pointer;
    height: 55px;
    padding: 0;
    line-height: 55px;
}
.sideMenu .container ul.menu.profile {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.sideBarFrame {
    padding: 10px;
    border-radius: 25px;
    border: 1px solid #dedede;
    box-shadow: #dedede;
}

.infoSideBar {
    text-align: center;
    font-weight: 600;
}

.sideMenu .container ul.menu.profile .nonEditable {
    margin-bottom: 30px;
}
.sideMenu .container ul.menu.profile .nonEditable p {
    margin: 5px 0;
}

.sideMenu .container ul.menu.profile .editable {
    padding-top: 10px;
}

.sideMenu .close:hover {
    animation: spin .4s ease;
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
}

.iframe {
    max-height: 100%;
    border: none;
}

.closeButton {
    top: 8px;
    right: 8px;
    color: #ccc;
    display: inline-block;
    position: absolute !important;
}

.closeButton i {
    width: 25px;
    height: 25px;
    color: #ccc;
}

.femaleImage {
    position: absolute;
    top: -114px;
    width: 436px;
    right: -168px;
}

.doctorImage {
    position: absolute;
    top: -105px;
    width: 436px;
    left: -120px;
}

.dialogTitle {
    font-size: 1rem;
    /*color:#5372e4;*/
    /*padding-right: 55px !important;*/
    padding-top: 68px !important;
    padding-bottom: 0px;
    text-align: center;
}

.titleModal {
    font-family: Poppins-Medium, Poppins;
    max-width: 450px;
    font-size: 21px;
    font-weight: 400;
    letter-spacing: .5px;
    line-height: 32px !important;
    display: inline-block;
}

.titleModalPhone {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.0075em;
    text-align: center;
    margin: 40px 0 20px 0;
    width: 100%;
    display: block;
}

.modalLogo {
    height: 60px;
    margin-top: 50%;
    transform: translateY(-50%);
    margin-left: -3px;
}

.modalLogoContainer {
    width: 90px;
    height: 90px;
    top: -20px;
    margin: 0 auto;
    text-align: center;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    overflow: visible;
    display: inline-block;
}

.modalFrame [class~="MuiDialog-paper"] {
    overflow: visible;
    background: #fff;
}

@media only screen and (max-width: 768px) {
    .titleModal {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        margin-top: 0;
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 1 !important;
        font-weight: 500;
        text-align: left;
        letter-spacing: .5px;
        max-width: 70vw;
    }

    .modalLogoContainer {
        top: -45px;
    }

    .modalFrame [class~="MuiDialog-paper"] {
        max-width: 90vw;
    }

    .dialogTitle {
        margin-bottom: 0 !important;
        padding: 16px !important;
        padding-bottom: 0 !important;
    }
}

@media only screen and (min-width: 768px) {

    .modalFrame [class~="MuiDialog-paper"] {
        max-width: 55vw;
    }
}

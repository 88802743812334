
.titleWrapper {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 0 15px !important;
    display: grid;
    align-content: center;
}




@media only screen and (min-width: 568px) {
    button.MuiButton-root.loginLogoutButton:hover {
        border: 2px solid white;
    }
    button.MuiButton-root.loginLogoutButton {
        border: 2px solid white;
        border-radius: 100px;
        color: #fff;
        font-weight: 500;
        width: 200px;
        height: 37px;
        padding: 8px 16px;
        font-size: .675rem;
        /*border: 1px solid #fff;*/
        margin-top: 15px;
        margin-bottom: 15px;
    }
}
@media only screen and (max-width: 568px) {
    button.MuiButton-root.loginLogoutButton{
        height: 70px;
        width: 70px;
        border-radius: 100px;
    }
    button.MuiButton-root.loginLogoutButton {
        padding: 2px;
        text-align: center;
    }
}

@media only screen and (max-width: 360px) {
    button.MuiButton-root.loginLogoutButton {
        padding: 2px;
    }
    button.MuiButton-root.loginLogoutButton span.MuiButton-label span {
        padding: 0;
    }
}

/*.headerTitle h1 {*/
.headerTitle {
    font-size: 34px;
    text-align: center;
}


@media only screen and (max-width: 400px) {
    .headerTitle {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
    }
}

.headerTitle p {
    margin-block-start: 0.4rem;
    margin-block-end: 0.4rem;
}

.trianglePhone {
    text-align: center;
    margin-top: 5vh;
    padding: 10px 16px;
    border: 2px solid #edf1f8;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 16%);
    font-family: Montserrat, sans-serif;
    color: #819ab4;
    font-size: 16px;
    font-weight: 400;
}


.backButton button {
    width: 44px !important;
    height: 44px !important;
    line-height: 44px !important;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4776e6+0,8e54e9+100 */
    /* background: #fff; Old browsers */
    color: yellow;
    /* background: -moz-linear-gradient(top, #4776e6 0%, #8e54e9 100%); FF3.6-15 */
    /* background: -webkit-linear-gradient(top, #4776e6 0%,#8e54e9 100%); Chrome10-25,Safari5.1-6 */
    /* background: linear-gradient(to bottom, #4776e6 0%,#8e54e9 100%); W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4776e6', endColorstr='#8e54e9',GradientType=0 ); IE6-9 */
}

.backButton button {
    background-color: white !important;
}

.backButton button span {
    line-height: 44px !important;
    color: rgb(142, 142, 142) !important;
}

.MuiButton-root.headerBackButton {
    background-color: white;
    color: #5372e4;
    border-radius: 0;
    border: 0.5px solid #5372e4;
    padding: 8px 16px;
    height: 37px;
    font-size: 0.675rem;
    margin-top: 15px;
}

.userName {
    text-align: right;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
    font-weight: 400;
    margin-top: 15px;
}

.userName a {
    cursor: pointer;
}

.accountContainer {
    width: 100%;
    text-align: center;
}
/*.accountContainer .fa-user-o {*/
/*    top: 0;*/
/*    right: 0;*/
/*    z-index: 1;*/
/*    margin-top: 20px;*/
/*    font-size: 36px;*/
/*    font-weight: bold;*/
/*    cursor: pointer;*/
/*}*/

h3.title {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-weight: 600 !important;
    color: #000 !important;
}

/* Small screen */
h3.title, h3.title span {
    font-size: 1.1rem !important;
    text-align: center;
}

.avatarBig {
    display: none !important;
}

.bravoAvatar {
    width: 72px;
    height: 72px;
}

.avatarWrapper {
    text-align: left !important;
    /* min-width: 110px; */
}

/* Big screen */
@media only screen and (min-width: 576px) {
    .backButton button {
        width: 64px !important;
        height: 64px !important;
        line-height: 64px !important;
    }

    .backButton button span {
        line-height: 64px !important;
    }

    .userName {
        font-size: 15px;
    }

    h3.title, h3.title span {
        font-size: 1.4rem !important;
        text-align: left;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    /*.titleWrapper {*/
    /*border: 2px solid rgb(92, 71, 185);*/
    /*}*/
}
.profession-subtitle {
    color: #fff;
    font-size: 24px;
    text-align: center;
}
.progressBar {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-content: center;
}

.arrow, .arrowMobile {
    position: fixed;
    display: none;
}

@media only screen and (min-width: 480px) and (min-height: 950px) {
    .arrowMobile {
        display: none;
    }

    .arrow {
        display: block;
        height: 700px;
        right: 205px;
        margin-top: 30px;
    }
}

@media only screen and (min-width: 480px) and (min-height: 730px)  and (max-height: 950px) {
    .arrowMobile {
        display: none;
    }

    .arrow {
        display: block;
        height: 495px;
        right: 288px;
        margin-top: 34px;
    }
}

@media only screen and (min-width: 480px) and (max-height: 730px)  and (min-height: 450px) {
    .arrowMobile {
        display: none;
    }

    .arrow {
        display: block;
        height: 405px;
        right: 233px;
        margin-top: 13px;
    }
}

@media only screen and (max-width: 480px) and (min-height: 700px) {
    .arrowMobile {
        display: block;
        height: 400px;
        right: 47px;
        margin-top: 57px;
    }

    .arrow {
        display: none;
    }
}

@media only screen and (max-width: 480px) and (max-height: 700px) {
    .arrowMobile {
        display: block;
        height: 300px;
        right: 47px;
        margin-top: 57px;
    }

    .arrow {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .titleWrapper {
        margin-left: -15px;
        /*-webkit-border-radius: 20px;*/
        /*-webkit-border-bottom-left-radius: 0;*/
        /*-moz-border-radius: 20px;*/
        /*-moz-border-radius-bottomleft: 0;*/
        /*border-radius: 20px;*/
        /*border-bottom-left-radius: 0;*/
    }

    .progressContainer {
        width: 60%;
        margin: auto;
    }

    .progressContainer div {
        top: 30px
    }

    .progressBar div {
        justify-self: center;
        width: 700px;
    }

    .avatarWrapper {
        text-align: right !important;
    }

    .avatarBig {
        display: inline-flex !important;
    }

    .avatarSmall {
        display: none !important;
    }

}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .progressBar div {
        justify-self: center;
        width: 900px;
    }
}

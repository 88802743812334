.circle {
    position: absolute;
    width: 135px;
    left: -25px;
    top: -15px;
}

.listEntry {
    margin-left: 10px;
}

.listEntry::before {
    background: url("check.png") no-repeat 0 0;
    background-size: 100%;
    content: "";
    width: 16px;
    height: 16px;
    position: relative;
    display: inline-block;
    left: -10px;
}

.affiliateButtons {
    margin-bottom: 0 !important;
}

.popupRewardText {
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    padding: 20px 0;
    margin: 35px auto;
    border-top: solid 1px rgba(80, 80, 80, 0.3);
    border-bottom: solid 1px rgba(80, 80, 80, 0.3);
}

.popupImageStyle {
    left: 50%;
    transform: translateX(-50%);
    width: 350px;
    top: -200px;
    margin-left: -20px;
}
.inactivityWrapper{
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #ffffff;
    background: rgba(255, 255, 255, 0.95);
    z-index: 9999;
    position: fixed;
    display: none;
}

.visible {
    display: flex;
    align-items: center;
    justify-content: center;
}

.restricted {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 1);
}

.inactivityContent {
    text-align: center;
}

.inactivityLogo {
    width: 100px;
}

.inactivityText {
    line-height: 1.3;
    color: #5380ed;
    padding: 0 10px;
}

.inactivityButton {
    background: #4cd964!important;
    width: 150px;
    height: 40px;
    margin: 15px!important;
    color: #ffffff!important;
    font-weight: bold!important;
}
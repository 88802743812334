body {
    background-color: white;
}

.container-job{
    /* height: 100vh; */
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 5%;
    margin-left: 150px;
    margin-right: 150px;
    padding-bottom: 5px;
}

.wrapper-col {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
}

.file {
    display: none;
}

#job-button {
    font-size: 15px;
    font-family: Monospace;
    margin-top: 20px;
    width: 100%;
    height: 40px; 
    border: solid 2px #3451b9;
    background-color: white;
    color: #3451b9;
    font-family: Montserrat, Roboto, sans-serif;
    font-size: 19px;
    font-weight: 400;
    border-radius: 10px;
 }

 .file-img { 
     margin-left: 10px;
     height: 20px;
 }

 .img-job-woman {
     margin-right: 20px;
 }

 .img-job-man {
    margin-left: 20px;
 }

 #job-button:hover {
     background-color: #3451b9;
     color: white;
 }

 #suivant-job {
     margin-left: 10px;
     margin-right: 10px;
     margin-top:20px;
     width: 240px;
     background-color: #3451b9;
 }

 .error {
     margin-top: 3px;
     text-align: center;
     font-size: 12px;
     color: red;
 }


 .img-div {
     display: flex;
 }

 .field-job {
     width: 240px;
 }

 @media only screen and (max-width: 535px) {
    .label-div-job {
        width: 240px;
    }
  }

 .label-job {
     width: 240px;
     margin-bottom: 10px;
     text-align: center;
     font-size: 22px;
     font-weight: bold;
     color: black;
 }

 .label-job1 {
    width: 270px;
    font-size: 22px;
    padding-left: 15px;
    margin-bottom: 5px;;
    font-weight: bold;
    color: black;
 }

 .job-div {
     width: 240px;
 }

 .text-logged {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 50px;
    color: #ADFF2F;
 }

 .submitted-text {
    margin: 50px;
    text-align: center;
    font-weight: bold;
    font-size: 50px;
    color: #3b7cd1;
 }

 .submitted-text-phone {
    margin: 100px;
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    color: #3b7cd1;
 }

 .redirect {
    width: 200px;
    margin: auto;
 }
.loader_loaderContainer__1rmOT {
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.9);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: fixed;
    z-index: 1400;
}

.loader_loader__3YnrM {
    max-width: 50%;
}

.loader_loaderLogo__2JIxh {
    max-width: 100px;
    margin-top: 45px;
    margin-left: 45px;
}

@keyframes loader_ldsEclipse__2FKR_ {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes loader_ldsEclipse__2FKR_ {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader_ldsEclipse__2FKR_ {
    width: 100%;
    height: 100%;
    position: relative;
}

.loader_ldsEclipse__2FKR_ div {
    position: absolute;
    -webkit-animation: loader_ldsEclipse__2FKR_ 1s linear infinite;
    animation: loader_ldsEclipse__2FKR_ 1s linear infinite;
    width: 180px;
    height: 180px;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    -webkit-transform-origin: 90px 92.5px;
    transform-origin: 90px 92.5px;
}

.loader_ldsEclipse__2FKR_ {
    width: 200px !important;
    height: 200px !important;
    -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
    transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}

.modal_iframe__5PWUl {
    max-height: 100%;
    border: none;
}

.modal_closeButton__DzgTK {
    top: 8px;
    right: 8px;
    color: #ccc;
    display: inline-block;
    position: absolute !important;
}

.modal_closeButton__DzgTK i {
    width: 25px;
    height: 25px;
    color: #ccc;
}

.modal_femaleImage__2FKL6 {
    position: absolute;
    top: -114px;
    width: 436px;
    right: -168px;
}

.modal_doctorImage__3tgoZ {
    position: absolute;
    top: -105px;
    width: 436px;
    left: -120px;
}

.modal_dialogTitle__36Iuc {
    font-size: 1rem;
    /*color:#5372e4;*/
    /*padding-right: 55px !important;*/
    padding-top: 68px !important;
    padding-bottom: 0px;
    text-align: center;
}

.modal_titleModal__kzb4A {
    font-family: Poppins-Medium, Poppins;
    max-width: 450px;
    font-size: 21px;
    font-weight: 400;
    letter-spacing: .5px;
    line-height: 32px !important;
    display: inline-block;
}

.modal_titleModalPhone__3U8ST {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 0.0075em;
    text-align: center;
    margin: 40px 0 20px 0;
    width: 100%;
    display: block;
}

.modal_modalLogo__NoULp {
    height: 60px;
    margin-top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    margin-left: -3px;
}

.modal_modalLogoContainer__1yUs1 {
    width: 90px;
    height: 90px;
    top: -20px;
    margin: 0 auto;
    text-align: center;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    overflow: visible;
    display: inline-block;
}

.modal_modalFrame__z41ki [class~="MuiDialog-paper"] {
    overflow: visible;
    background: #fff;
}

@media only screen and (max-width: 768px) {
    .modal_titleModal__kzb4A {
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        margin-top: 0;
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 1 !important;
        font-weight: 500;
        text-align: left;
        letter-spacing: .5px;
        max-width: 70vw;
    }

    .modal_modalLogoContainer__1yUs1 {
        top: -45px;
    }

    .modal_modalFrame__z41ki [class~="MuiDialog-paper"] {
        max-width: 90vw;
    }

    .modal_dialogTitle__36Iuc {
        margin-bottom: 0 !important;
        padding: 16px !important;
        padding-bottom: 0 !important;
    }
}

@media only screen and (min-width: 768px) {

    .modal_modalFrame__z41ki [class~="MuiDialog-paper"] {
        max-width: 55vw;
    }
}

.cookieBar {
    /* background-color: #8e54e9 !important; */
    /*left: 50vw!important;;*/
    right: 0!important;
    bottom: 0!important;
    /*transform: translate(-50%, 0);*/
    /*transform: translate(-50%, -50%);*/

    height: 350px !important;
    width: 250px !important;
    /*padding: 30px 10px;*/
    padding: 0 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4776e6+0,8e54e9+100 */
    /*background: #4776e6; !* Old browsers *!*/
    /*background: -moz-linear-gradient(top, #4776e6 0%, #8e54e9 100%); !* FF3.6-15 *!*/
    /*background: -webkit-linear-gradient(top, #4776e6 0%,#8e54e9 100%); !* Chrome10-25,Safari5.1-6 *!*/
    /*background: linear-gradient(to bottom, #4776e6 0%,#8e54e9 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!*/
    -webkit-align-items: center;
            align-items: center;
    border-radius: 5px;
    box-shadow: 1px 1px 11px 0 #000;
    color: black;
    /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4776e6', endColorstr='#8e54e9',GradientType=0 ); !* IE6-9 *!*/
    position: fixed !important;
    z-index: 9999999999 !important;
}

.cookieBar .cookie-message {
    font-size: 15px;
    line-height: 1.25em;
    font-weight: 400;
    text-align: center;
    letter-spacing: normal;
}

.button-close {
    display: block !important;
    width: 120px !important;
    height: 40px;
    color: white !important;
    border-radius: 20px !important;
    background-color: #ECB941 !important;
    text-align: center !important;
    cursor: pointer !important;
    margin: auto !important;
}

.modalCookie .MuiDialog-paper {
    overflow: visible;
    background-color: #F3F0B0 !important;
}
.cookieBar .button-close {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    font-family: 'Montserrat' !important;
    margin: auto !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}

.titleCookie {
    font-weight:bold;
    text-align:center;
    margin-right:40px;
    margin-left:40px;
}

.infoCookie {
    position: absolute;
    top: -90px;
    right: -60px;
    width: 200px;
    height: 100px;
    background-color: #5372e4;
    color: white;
    display: none;
}

.cookieButton {
    width: 100%;
    font-weight: 400!important;
    height: 40px;
    color: white!important;
    margin-top: 20px!important;
    text-transform: capitalize!important;
    font-size: 20px!important;
}

.checkboxCookie {
    text-align: center!important;
}

.cookieButtonOther {
    font-weight: 400!important;
    height: 40px;
    width: 100%;
    margin-top: 10px!important;
    text-transform: capitalize!important;
    font-size: 20px!important;
}
.cookieBar + #cookieBackground {
    display: block;
}
#cookieBackground {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    position: fixed;
    background-color: gray;
    z-index: 1299;
    display: none;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(/static/media/Poppins-Regular.8b6af8e5.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-weight: 500;
    src: local('Poppins'), url(/static/media/Poppins-SemiBold.4cdacb8f.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Black';
    src: local('Poppins'), url(/static/media/Poppins-Black.0573b923.ttf) format('truetype');
}
.rdp-day,.rdp-head_cell,.rdp-caption_dropdowns {
    color: #666666 !important;
}
@font-face {
    font-family: 'Poppins-Medium';
    src: local('Poppins'), url(/static/media/Poppins-Medium.f61a4eb2.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins-Light';
    src: local('Poppins'), url(/static/media/Poppins-Light.f6ea751e.ttf) format('truetype');
}

@font-face {
    font-weight: 700;
    font-family: 'Poppins';
    src: local('Poppins'), url(/static/media/Poppins-Bold.a3e0b5f4.ttf) format('truetype');
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-size: 16px;
    font-family: Poppins, sans-serif !important;
    color: #666666;
    background: #fff;
    overflow-x: hidden;
    background-size: cover;
    background-attachment: fixed;
}
.additionalError .MuiInputBase-formControl {
    border: 1px solid red !important;
}
body.prevent-scrolling, body.quick-pricing-prevent {
    overflow: hidden;
}

.large-header .headerTitle {
    font-size: 26px;
}

.inline-block {
    display: inline-block;
}

.capitalize {
    text-transform: capitalize;
}

#g-recaptcha {
    -webkit-transform: scale(0.8) !important;
            transform: scale(0.8) !important;
    -webkit-transform-origin: 0 0 !important;
            transform-origin: 0 0 !important;
}

a {
    color: #5372e4;
    cursor: pointer;
}

.purple {
    color: #5372e4 !important;
}

.white {
    color: #ffffff;
}

.hardWhite {
    color: #fff !important;
}

.lightBlue {
    color: #2daddd;
}

.capital {
    text-transform: capitalize;
}

button.formButton.lightBlueBg,
button.formButton.lightBlueBg:hover {
    background-color: #2daddd;
}

.successMessage, .errorMessage {
    font-size: 18px;
    text-align: center;
}

.successMessage {
    color: green;
}

.errorMessage {
    color: #e3332e;
}

.verticalCenter {
    height: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.mainContainer {
    width: 100vw;
    height: 100vh;
}

.mainRow {
    margin: auto 0 !important;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
}

@media only screen and (min-width: 1300px) {
    .mainContainer {
        /*max-width: 60vw;*/
    }
}

@media only screen and (min-width: 600px) {
    .mainContainer {
        /*max-width: 90vw;*/
    }
}

.MuiFormHelperText-root.Mui-error:after {
    content: "!";
}

.MuiFormHelperText-root.Mui-error {
    color: #ffcccb !important;
    font-weight: 700;
}

.mainContainer .content {
    max-width: 100%;
    margin-left: 300px;
    min-height: 85vh;
    /*width: 100%;*/
    /*background-color: #f8f8f8;*/
    /*background-color: #f8f8f8;*/
    /*background-color: rgb(248,248,250);*/
    background: rgb(245, 245, 245);
    /*background-color: #fff;*/
    /*padding-top: 100px;*/
    /*padding-left: 60px !important;*/
    /*padding-right: 60px !important;*/
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    box-sizing: border-box;
    /* -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2); */
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, .2); */
    overflow-x: hidden;
}

.mainContainer .sidebar {
    /*display: flex;*/
    width: 320px;
    padding: 0 !important;
    /*min-height: 100vh!important;*/
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    min-height: 100vh;
    background-color: #fff;
    /* -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
     box-shadow: 0 2px 10px rgba(0, 0, 0, 0);*/
    z-index: 999;
    position: fixed;
    /*#1*/
    /*background-image: linear-gradient(*/
    /*        -90deg, #5372e4, #3c58bb);*/
    /*#2*/
    /*background-color: #3451b9;*/
}

div.sidebarStepper, div.personalSideBar {
    /*#1*/

    /*#2*/
    color: white;
}

/*.logoContainer {*/
/*    background-color: #001242;*/
/*    color: white;*/
/*}*/
svg.MuiStepIcon-root.MuiStepIcon-active,
svg.MuiStepIcon-root.MuiStepIcon-completed {
    color: #4cd964 !important;
}

svg.MuiStepIcon-root.MuiStepIcon-active .MuiStepIcon-text {
    fill: #000000;
}

svg.MuiStepIcon-root {
    color: #5380ed;
}

div.sidebarStepper .MuiStepLabel-label {
    color: #ffffff;
}

/*.accountContainer button.MuiButton-root.loginLogoutButton {*/
/*    background: #001242;*/
/*}*/
@media screen and (max-width: 1024px) {
    div.mainContainer .sidebar {
        /*max-width: 95vw;*/
        display: none;
    }

    div.mainContainer .content {
        margin-left: 0;
    }

    div.mainContainer .navBarMobile {
        display: block;
    }

    .accountContainer {
        text-align: right;
    }

    .PersonalSpace div#header {
        display: block;
        background-color: #292F44;
    }

    div.triangle {
        margin-left: 3vw;
        margin-top: 6vh;
    }

    .content .triangle {
        display: none;
    }

    .accountContainer button.MuiButton-root.loginLogoutButton {
        margin-top: 10px;
        background: #5380ed;
    }
}

/*offer page*/
.premiumPricing {
    text-align: center;
    font-weight: 700;
    font-size: 51px;
    letter-spacing: -2.11px;
    color: #88BAC7;
    opacity: 1;
}

.globalPricing {
    text-align: center;
    font-weight: 900;
    font-size: 59px;
    letter-spacing: -2.59px;
    color: #F6B60D;
    opacity: 1;
}

.traitImage {
    width: 350px;
    position: relative;
    top: -183px;
    right: 40px;
    margin-bottom: -350px;
}

.offerBenefits {
    font-size: 23px;
}

/*end offer page*/

.MuiSlider-mark {
    height: 6px !important;
}

span.MuiSlider-root .MuiSlider-markActive {
    background-color: #F6B60D !important;
}
.orangeBg {
    background-color: #F6B60D!important;
}
.orange {
    color: #F6B60D!important;
}
.roundedButton {
    background-color: yellow;
}

.mainContainer .contentWrapper {
    min-width: 80%;
    padding-bottom: 80px;
    margin: 0 auto;
    transition: -webkit-flex 0.3s ease-in-out;
    transition: flex 0.3s ease-in-out;
    transition: flex 0.3s ease-in-out, -webkit-flex 0.3s ease-in-out;
}

.centeredText {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}

.primary {
    color: #5380ed;
}

.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.no-margin-top {
    margin-top: 0;
}

.no-margin-bottom {
    margin-bottom: 0;
}

@media screen and (max-width: 700px) {
    .centeredText {
        text-align: center;
        font-size: 14px;
    }
}

.centeredAlign {
    -webkit-align-items: center !important;
            align-items: center !important;
}

.separator {
    border-color: rgba(86, 128, 237, 0.8);
    margin: 20px 0 50px 0;
}

.separatorHeading {
    font-size: 24px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    /*border-bottom: 1px solid rgba(50, 50, 50, 0.2);*/
}

.pretSeparator {
    margin: 18px auto;
    width: 500px;
    max-width: 100%;
}

.underlined {
    border-bottom: 1px solid rgba(50, 50, 50, 0.2);
}

.normal {
    font-weight: normal;
}

.firstRow, .paddingTop {
    padding-top: 2rem;
}

.MuiMenuItem-root {
    font-family: Montserrat, sans-serif !important;
    font-size: 16px !important;
    display: block !important;
    white-space: normal !important;
}

.MuiInputBase-input {
    font-family: Montserrat, sans-serif !important;

    padding: 16.5px 14px !important;
    font-size: 16px !important;
}

.MuiInputBase-input.MuiSelect-select {
    padding: 15px 14px !important;
    border-radius: 9px;
}

.MuiInputLabel-outlined:not(.MuiInputLabel-shrink) {
    -webkit-transform: translate(14px, 17px) scale(1) !important;
            transform: translate(14px, 17px) scale(1) !important;
}


.MuiTypography-body1 {
    font-size: 0.8rem;
}

.MuiInputBase-input.Mui-disabled {
    cursor: not-allowed !important;
    color: rgba(0, 0, 0, 0.38);
}

.center_radioButtons {
    text-align: center;
}

.radioGroup.MuiFormGroup-root {
    margin-top: 10px;
    margin-bottom: 0;
    margin-left: 70px;
    width: 100%;
    max-width: 800px !important;
}

.radioGroup.alignLeft.MuiFormGroup-root {
    -webkit-justify-content: left;
            justify-content: left;
    margin-left: 0;
}

.radioButton .MuiRadio-colorSecondary.Mui-checked {
    color: #75ACBA;
}

.radioButton .MuiRadio-colorSecondary.Mui-checked:hover {
    background-color: rgba(86, 128, 237, 0.04);
}

.radioButton .MuiRadio-colorSecondary:hover {
    background-color: rgba(86, 128, 237, 0.04);
}

.radioInput {
    display: -webkit-flex;
    display: flex;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

div.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0;
}

.backImg {
    cursor: pointer;
}

@media only screen and (min-width: 500px) {
    .radioInput {
        /*center on desktop, align left on mobile*/
        -webkit-justify-content: center;
                justify-content: center;
    }
}

.MuiFormControl-root .MuiTextField-root label {
    font-size: 19px !important;
}

span.MuiFormControlLabel-label,
p.MuiFormHelperText-root,
div.MuiFormControl-root label.MuiInputLabel-root.MuiInputLabel-formControl {
    font-family: Montserrat, sans-serif;
}

label.MuiInputLabel-root + .MuiInput-formControl {
    font-size: 18px !important;
    /*border-radius: 4px;*/
}

.inputLabel {
    color: #666666 !important;
    font-size: 15px !important;
    font-weight: 500;
}

div:not(.blueLabel).MuiFormControl-root label.MuiInputLabel-root.MuiInputLabel-formControl {
    color: #666666 !important;
    padding: 0 15px;
    width: 100%;
    position: relative;
    height: 22px;
    line-height: 1.1;
    -webkit-transform: translate(0, 1.5px) scale(0.75);
            transform: translate(0, 1.5px) scale(0.75);
    -webkit-transform-origin: top left;
            transform-origin: top left;
    font-size: 17px !important;
    font-weight: 500;
}

.blueLabel div.MuiFormControl-root label.MuiInputLabel-root.MuiInputLabel-formControl, .blueLabel .MuiFormLabel-root {
    color: #5372e4 !important;
}

div.MuiFormControl-root.social_phone label.MuiInputLabel-root.MuiInputLabel-formControl {
    font-size: 25px !important;
}

p.MuiFormHelperText-root {
    color: #000;
    margin-left: 13px;
    margin-top: 5px;
    font-size: 12px;
    line-height: 1;
}

.MuiTextField-root .MuiInputBase-root.MuiInput-root,
.MuiFormControl-root .MuiInputBase-root.MuiInput-root {
    background: #fff;
    border-radius: 9px;
}


.MuiTextField-root .MuiInputBase-root.MuiInput-root:after,
.MuiFormControl-root .MuiInputBase-root.MuiInput-root:after {
    border-radius: 4px;
    /*border-bottom: 3px solid #5380ed;*/
    height: 100%;
}

::-webkit-input-placeholder {
    opacity: 0.7 !important
}

:-ms-input-placeholder {
    opacity: 0.7 !important;
}

::placeholder {
    opacity: 0.7 !important;
}

/*.MuiTextField-root .MuiInputBase-root.MuiInput-root:before {*/
/*    border: 2px solid rgba(0, 0, 0, 0.23)!important;*/
/*}*/
.MuiInputBase-adornedEnd span:not(.MuiIconButton-label) {
    margin-right: 15px;
}

/**/

.block {
    /*background: #ffff;*/
    /*padding: 30px 20px;*/
    /*margin: 5px 0;*/
    /*-webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);*/
    /*box-shadow: 0 2px 10px rgba(0, 0, 0, .2);*/
    /*border-radius: 4px;*/
}

.tip {
    text-decoration: underline;
}

.hint {
    /*margin-top: 12px;*/
    display: inline-block;
}

.bold {
    font-weight: bold;
}

.question-icon {
    font-size: 15px !important;
}

.question-icon.reversed {
    color: #ffffff;
    background: #5372e4;
}

.answer {
    font-weight: bold;
    padding-left: 15px;
}

a {
    color: #5372e4;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

@-webkit-keyframes slideRight {
    /* style rules */
    from {
        opacity: 0;
        margin-right: -150px;
    }
    to {
        opacity: 1;
        margin-right: 0;
    }
}

@keyframes slideRight {
    /* style rules */
    from {
        opacity: 0;
        margin-right: -150px;
    }
    to {
        opacity: 1;
        margin-right: 0;
    }
}

/**
    Odometer CSS START
 */
.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
}

.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-default .odometer-digit {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
    text-align: left;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
    display: block;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
    display: block;
    -webkit-backface-visibility: hidden;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-default .odometer-digit .odometer-value {
    display: block;
    -webkit-transform: translateZ(0);
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
    position: absolute;
}

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up .odometer-ribbon-inner {
    transition: -webkit-transform 2s;
    transition: transform 2s;
    transition: transform 2s, -webkit-transform 2s;
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
}

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-default.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
    transition: -webkit-transform 2s;
    transition: transform 2s;
    transition: transform 2s, -webkit-transform 2s;
    -webkit-transform: translateY(0);
    transform: translateY(0);
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-default {
    /* font-family: "Helvetica Neue", sans-serif; */
    line-height: 1.1em;
}

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-theme-default.odometer-animating-up.odometer-animating .odometer-ribbon-inner,
.odometer.odometer-auto-theme .odometer-value, .odometer.odometer-theme-default .odometer-value {
    text-align: center;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-default .odometer-digit .odometer-value.odometer-last-value {
    width: 100%;
}

.odometer.odometer-auto-theme .odometer-inside .odometer-digit:last-child {
    display: none;
}

/**
    Odometer CSS END
 */


/**
    After for one or the other
 */

.orOther {
    position: absolute;
    right: 0;
    margin-top: 50px;
    text-transform: lowercase;
}

@media screen and (max-width: 768px) {
    .orOther:after {
    }
}

.step2-tooltip {
    border-radius: 21px;
    text-align: center;
}

.step2-tooltip:after {
    border-top-color: #5372e4 !important;
}

@media only screen and (max-width: 500px) {
    .step2-tooltip {
        left: 0 !important;
    }
}

div.progression {
    margin: 0 auto;
    padding-top: 15px;
    text-align: center;
}

div.progression span {
    border-radius: 100%;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    text-align: center;
    line-height: 20px;
    font-weight: 600;
}

div.progression span.selected {
    border: 3px solid #5372e4;
    color: #5372e4;
}

div.progression span:not(.selected) {
    border: 3px solid #adb5bd;
    color: #adb5bd;
}

.stepper {
    max-width: 300px;
    margin: 0 auto;
}

.sidebarStepper {
    margin-top: 50px;
}

.sidebarStepper .MuiStepLabel-label {
    font-size: 16px;
    font-family: Montserrat, sans-serif !important;
    line-height: normal;
    letter-spacing: normal;
}

.sidebarStepper .MuiStepConnector-root.Mui-disabled:first-child {
    /*.MuiStepConnector-line {*/
    border-color: #5372e4;
}

.sidebarStepper .MuiStepLabel-label.MuiStepLabel-completed {
    font-weight: normal;
}

.sidebarStepper .stepperEnd {
    font-size: 16px;
}

.MuiSelect-select, .MuiOutlinedInput-adornedEnd {
    background-color: #fff !important;
}

svg.MuiStepIcon-root.MuiStepIcon-active,
svg.MuiStepIcon-root.MuiStepIcon-completed {
    color: #5372e4;
}

span.MuiButton-label span {
    padding: 0 5px;
}

.authenticationButtons {
    text-align: center;
}

.franceConnect {
    padding: 0 1px;
}

.franceConnect:after {
    background-color: #fbfbfb;
    position: absolute;
    right: 0;
    margin-right: -12px;
    top: 115px;
    content: "ou";
    color: #5372e4;
    font-size: 18px;
    font-weight: 500;
    text-transform: lowercase;
}

.alternativeMethods a {
    color: #fff;
}

.authenticationSection .franceConnect:after {
    display: none;
}

.franceConnectSection {
    border-right: 1px solid rgba(50, 50, 50, 0.2);
    padding-bottom: 30px;
}

.authenticationSection .franceConnectSection {
    border-right: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.authenticationSection .ou {
    color: #fff;
}

@media only screen and (max-width: 768px) {
    .franceConnect:after {
        display: none;
    }

    .franceConnectSection {
        margin-bottom: 10px;
        padding-bottom: 20px;
        border-right: none;
        border-bottom: 1px solid rgba(50, 50, 50, 0.2);
    }
}

button.kep-login-facebook {
    width: 100%;
    margin: 0 auto 15px auto;
    display: block;
    text-transform: capitalize;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 0;
    font-family: Montserrat, Roboto, sans-serif;
    font-weight: 400;
    border-radius: 4px !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.googleLogin {
    width: 100%;
    padding: 2px !important;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.googleLogin:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .5) !important;
    border: none;
}

.googleLogin div {
    left: 25px;
    top: 15px;
    margin: 0 !important;
    padding: 0 !important;
    position: absolute;
}

.googleLogin svg {
    /*top: 2px;*/
    /*left: 5px;*/
}

.googleLogin span {
    width: 100%;
    padding: 10px !important;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400 !important;
    font-family: Montserrat, Roboto, sans-serif;
}

div#g-recaptcha {
    -webkit-transform-origin: center !important;
            transform-origin: center !important;
}

/*button.kep-login-facebook:after {*/
/*content: "ou";*/
/*position: absolute;*/
/*right: 0;*/
/*margin-right: -18px;*/
/*color: #7048e8;*/
/*font-size: 18px;*/
/*font-weight: 500;*/
/*text-transform: lowercase;*/
/*}*/

button.kep-login-facebook:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .5);
}

@media only screen and (max-width: 768px) {
    button.kep-login-facebook {
        width: 100%;
    }
}

.ou {
    content: "ou";
    font-size: 18px;
    font-weight: 500;
    text-transform: lowercase;
    padding-bottom: 15px;
}

@media only screen and (max-width: 576px) {
    .ou {
        content: "ou";
        font-size: 18px;
        font-weight: 500;
        text-transform: lowercase;
        padding-bottom: 5px;
    }
}

@media only screen and (min-width: 576px) {
    button.kep-login-facebook {
        line-height: 29px;
        min-width: 80%;
    }
}

@media only screen and (max-width: 575px) {
    button.kep-login-facebook:after {
        display: none;
    }
}

p.f14 {
    font-size: 14px
}

p.f15 {
    font-size: 15px;
}

p.w500 {
    font-weight: 500;
}

.fa.ptz {
    position: absolute;
    top: 3px;
    right: 3px;
}

.link {
    cursor: pointer;
    color: #5372e4;
}

.link:hover {
    text-decoration: underline;
    cursor: pointer;
}

.noWrap {
    white-space: nowrap;
}

.emailForm {
    width: 440px;
    max-width: 95%;
    margin: 0 auto;
    color: #fff;
}


.PersonalSpace #header {
    margin-bottom: 5px;
    /*background-color: #f8f8f8;*/
    /*background-color: #ffffff;*/
    background-color: rgb(253, 253, 253);
    display: none;
}

.PersonalSpace #header img.weDouLogo {
    margin-left: -15px;
    margin-top: 15px;
    margin-bottom: 10px;
    max-width: 130px;
    max-height: 80px;
}

.PersonalSpace #header .logoContainer {
    -webkit-justify-content: left !important;
            justify-content: left !important;
}

.PersonalSpace .show-menu,
.PersonalSpace .hide-menu {
    top: 20px;
    left: 20px;
    position: absolute;
    color: #5372e4;
    font-size: 40px;
    z-index: 100;
    display: none;
}

/*.PersonalSpace .hide-menu {*/
/*    color: #ffffff;*/
/*}*/

@media screen and (max-width: 1024px) {
    .PersonalSpace div.navBar ul.menu {
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
        transition: margin-top .2s ease-in;
        -webkit-transition: margin-top .2s ease-in;
        z-index: 99;
        margin-top: -100%;
        background-color: #ffffff;
        box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    }

    .PersonalSpace div.navBar.active ul.menu {
        margin-top: 0;
    }

    .PersonalSpace div.navBar.active .hide-menu {
        display: block;
    }

    .PersonalSpace div.navBar:not(.active) .show-menu {
        display: block;
    }

    .PersonalSpace div.navBar ul.menu li {
        display: block;
        color: #5372e4;
    }

    .PersonalSpace div.navBar ul.menu li.active {
        height: 60px;
        border-bottom: none;
    }

    .PersonalSpace div.navBar ul.menu li.active span {
        padding: 5px 15px;
        border-left: 3px solid #ffffff;
    }

    /*.avatarContainer .logoContainer .accountContainer button {*/
    /*    margin-top: 23px;*/
    /*}*/
}

.PersonalSpace .navBarMobile {
    display: none;
}

.personalSideBar {
    margin-top: 75px;
}

.PersonalSpace .navBar .menu {
    width: 100%;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    color: #fff;
    list-style: none;
    text-align: center;
    text-decoration: none;

    cursor: pointer;
    font-size: 15px;
    line-height: 60px;
    text-transform: uppercase;
    display: inline-block;
}

.personalSideBar p {
    color: #ffffff;
}

.PersonalSpace .navBar .menu li {
    text-align: center;
    padding: 2px 10px;
}

.PersonalSpace .navBar .menu li:hover {
    cursor: pointer;
    text-decoration: underline;
}

.PersonalSpace .navBar .menu li.active {
    height: 55px;
}

.mainPersonalContainer {
    /*padding: 30px 60px;*/
    background-color: #fff;
}

@media screen and (max-width: 768px) {

    .mainContainer div.content {
        padding-left: 25px !important;
        padding-right: 25px !important;
        padding-top: 0;
    }

    .mainPersonalContainer {
        /*padding: 30px 30px;*/
    }

    div.MuiDialog-paperWidthSm,
    div.MuiDialog-paperWidthMd {
        margin-left: 25px;
        margin-right: 25px;
    }
}

.mainPersonalContainer .header {
    font-weight: normal;
}

.mainPersonalContainer .MuiPaper-root.personalPaper {
    margin-bottom: 40px;
    color: #5372e4;
    background: #ffffff;
}

.documents .MuiPaper-root.personalPaper {
    color: #5372e4;
    background: #ffffff;
    /*color: #ffffff;*/
    /*background: #3451b9;*/
}

.documents .personalPaper .MuiTableCell-body {
    color: #5372e4;
    /*color: #ffffff;*/
}

.documents .question-icon {
    /*color: #ffffff;*/
}

.MuiPaper-root.personalPaper .paper {
    padding: 15px 30px;
    background: #5372e4;
    color: white;
}

.MuiPaper-root.personalPaper .paper.light {
    background: #2daddd;
}

.mainPersonalContainer .MuiPaper-root.personalPaper.paperRows {
    padding: 0 15px;
    color: #ffffff;
    background: #5372e4;
}

.paperRows > div {
    padding: 0 15px;
}

/*.mainPersonalContainer .buttonSection .formButton {*/
/*    width: 100%;*/
/*    padding-top: 5px;*/
/*    padding-bottom: 5px;*/
/*    color: #000;*/
/*    !*border-width: 3px;*!*/
/*    !*border-style: solid;*!*/
/*    !*border-color: #4776e6 #8d54e9 #8d54e9 #4776e6;*!*/
/*    background-color: transparent;*/
/*    !*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4776e6", endColorstr="#8e54e9", GradientType=0);*!*/
/*    font-weight: 400 !important;*/
/*}*/
.subtitle {
    font-weight: 700;
    color: rgb(45, 50, 45);
    font-size: 19px;
}

.mainPersonalContainer .semiBold {
    font-family: Montserrat, sans-serif;
    color: #1e2053;
    font-weight: 600;
    font-size: 1.9em;
    text-align: center;
}

table.documentsTable .name,
table.documentsTable .download {
    font-size: 18px;
}

table.documentsTable .download i {
    cursor: pointer;
    font-size: 30px;
    margin-right: 10px;
}

/*table.documentsTable .documentsBody tr:hover {*/
/*    background-color: white;*/
/*}*/

label.formButton {
    width: 100%;
}

/*iframe.sign {*/
/*    width: 100vw;*/
/*    height: 800px;*/
/*    margin-top: -30px;*/
/*    margin-left: -60px;*/
/*    border: none;*/
/*}*/
iframe.sign {
    margin-top: 50px;
    width: 1400px;
    max-width: 100%;
    height: 1000px;
    border: none;
}

.tableAnimated {
    padding: 0;
    margin: 20px 0;
    /*min-height: 380px !important;*/
}

.tableAnimated .spanAnimated {
    height: 0;
    opacity: 0;
    list-style: none;
    display: block;
}

.tableAnimated .spanAnimated.show {
    /*height: 2em;*/
    height: auto;
    opacity: 1;
    transition: all 0.4s ease-in;
}

.tableAnimated .col {
    /*border: 1px solid rgba(150,150,150,0.2);*/

    border-bottom: none;
    background: #fff;
}

.tableAnimated .col.last {
    border-bottom: 1px solid rgba(150, 150, 150, 0.2);
}

.tableAnimated .colWrap {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.tableAnimated p {
    margin: 12px;
}

@media screen and (max-width: 768px) {
    .tableAnimated {
        margin-top: 10px;
    }

    .tableAnimated p {
        margin: 16px 0;
    }
}

.MuiCheckbox-root.checkbox.Mui-checked .MuiSvgIcon-root {
    color: #5372e4;
}

li.MuiListItem-gutters {
    padding: 14px 16px;
}

li.MuiListItem-gutters.riskyProfessions {
    padding: 6px 16px;
}

.calendarWrapper:hover .calendarTip {
    display: block;
}

.calendarTip.show {
    display: block;
}

.calendarTip {
    display: none;
    /*display: block;*/
    width: 80%;
    text-align: center;
    font-size: 14px;
    margin-left: 10%;
    position: absolute;
    top: -110px;
    border-radius: 5px;
    color: #ffffff;
    left: 0;
    background: #5372e4;
    z-index: 1000000000;
}

.calendarTip:after {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    display: inline-block;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #5372e4;
    margin: -1px auto 0 auto;
}

@media screen and (max-width: 768px) {
    div.calendarTip {
        position: fixed;
        top: 100px;
    }

    /*div.calendarTip:after {*/
    /*margin: 31px auto 0 auto;*/
    /*}*/
}

@media screen and (max-width: 320px) {
    div.calendarTip {
        top: 30px;
    }
}

.phoneRegistration .PhoneInputInput {
    height: 39px;
    border-radius: 1px;
}

#tidio-chat-iframe {
    bottom: 110px !important;
}

.no-wrap {
    white-space: nowrap;
}

.MuiPaper-rounded {
    border-radius: 30px !important;
}
.white-no-header {
    width: 100%;
    min-height: 450px;
}
.content-no-header {
    min-width: 100%;
    min-height: 450px;
    color: #fff;
    background-color: #5372e3;
}

.content-no-header div.MuiFormControl-root label.MuiInputLabel-root.MuiInputLabel-formControl {
    height: 5px;
    color: #fff !important;
}

.content-no-header p.MuiFormHelperText-root {
    color: #fff !important;
}


.incomeRow {
    /*margin: 30px 200px auto;*/
    /*max-width: 75%;*/
    /*margin: 0 auto;*/
    padding: 30px;
}

.roundedIcon {
    color: rgb(50, 206, 155);
    border: 1px solid rgb(50, 206, 155);
    border-radius: 50%;
    padding: 10px;
    margin: 0 5px;
}

.underscoreSvg {
    width: 175px;
}
/*#tidio-iframe*/
/*li.show:nth-child(2) {*/
/*    transition-delay: 0.4s;*/
/*}*/

/*li.show:nth-child(3) {*/
/*    transition-delay: 0.8s;*/
/*}*/

.sideMenu_sideMenu__3qlqU .sideMenu_background__2vu0x {
    width: 100%;
    height: 100%;
    display: block;
    top: 0;
    left: 0;
    position: fixed;
}

.sideMenu_sideMenu__3qlqU.sideMenu_opened__8Q0Ru .sideMenu_background__2vu0x {
    background: rgba(50, 50, 50, 0.5);
    -webkit-animation: sideMenu_openModal__3hOh1 .3s ease;
            animation: sideMenu_openModal__3hOh1 .3s ease;
    display: block;
}

.sideMenu_sideMenu__3qlqU.sideMenu_closed__1RdEo .sideMenu_background__2vu0x {
    background: none;
    -webkit-animation: sideMenu_closeModal__VbHvS .3s ease;
            animation: sideMenu_closeModal__VbHvS .3s ease;
    display: none;
}

@-webkit-keyframes sideMenu_openModal__3hOh1 {
    0% {
        display: block;
        background: none;
    }
    100% {
        background: rgba(50, 50, 50, 0.5);
        display: none;
    }
}

@keyframes sideMenu_openModal__3hOh1 {
    0% {
        display: block;
        background: none;
    }
    100% {
        background: rgba(50, 50, 50, 0.5);
        display: none;
    }
}

@-webkit-keyframes sideMenu_closeModal__VbHvS {
    0% {
        background: rgba(50, 50, 50, 0.5);
        display: block;
    }
    100% {
        display: none;
        background: none;
    }
}

@keyframes sideMenu_closeModal__VbHvS {
    0% {
        background: rgba(50, 50, 50, 0.5);
        display: block;
    }
    100% {
        display: none;
        background: none;
    }
}

.sideMenu_sideMenu__3qlqU .sideMenu_container__1LfqQ {
    position: fixed;
    top: 0;
    right: 0;
    width: 500px;
    margin-right: -500px;
    background: #fff;
    /*background: #5c47b9;*/
    height: 100%;
    z-index: 200;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    transition: margin-right .3s ease;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    max-width: 90%;
}

.sideMenu_sideMenu__3qlqU.sideMenu_closed__1RdEo * {
    box-shadow: none!important;
}

.sideMenu_sideMenu__3qlqU.sideMenu_opened__8Q0Ru .sideMenu_container__1LfqQ {
    margin-right: 0;
}
.sideMenu_sideMenu__3qlqU .sideMenu_container__1LfqQ .sideMenu_header__2t5CL {
    padding: 15px;
    -webkit-flex: 0 1 25px;
            flex: 0 1 25px;
    /*box-shadow: 0 2px 10px rgba(0, 0, 0, .2);*/
}

.sideMenu_sideMenu__3qlqU .sideMenu_close__22KEi {
    position: absolute;
    /*top: 10px;*/
    /*right: 10px;*/
    top: 14px;
    right: 14px;
    font-size: 26px;
    /*color: #fff;*/
    cursor: pointer;
}

.sideMenu_sideMenu__3qlqU .sideMenu_container__1LfqQ h3 {
    /*color: #5372e4;*/
    /*color: #fff;*/
    font-weight: 500;
    text-align: center;
    /*border-bottom: 1px solid #7048e8;*/
    /*border-bottom: 1px solid rgba(255, 255, 255, 0.5);*/
    width: 90%;
    margin: 0 auto;
}

.sideMenu_sideMenu__3qlqU .sideMenu_container__1LfqQ ul.sideMenu_menu__1q10J {
    text-align: left;
    list-style: none;
    width: 90%;
    margin: 0 auto;
    padding: 0;
}

.sideMenu_sideMenu__3qlqU .sideMenu_container__1LfqQ ul.sideMenu_menu__1q10J li {
    /*color: #fff;*/
    padding: 5px 0;
    font-size: 16px;
    font-weight: 300;
}
.sideMenu_sideMenu__3qlqU .sideMenu_uppercase___Hae- {
    text-transform: uppercase;
}
.sideMenu_sideMenu__3qlqU .sideMenu_goToBonjour__4ddwq {
    cursor: pointer;
}
.sideMenu_sideMenu__3qlqU .sideMenu_container__1LfqQ ul.sideMenu_menu__1q10J li i {
    margin-right: 15px;
}

.sideMenu_sideMenu__3qlqU .sideMenu_container__1LfqQ ul.sideMenu_menu__1q10J.sideMenu_authentication__XZuWe {
    width: 100%;
    padding-left: 25px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    display: block;
    -webkit-flex: 0 1 55px;
            flex: 0 1 55px;
}

.sideMenu_sideMenu__3qlqU .sideMenu_container__1LfqQ ul.sideMenu_menu__1q10J.sideMenu_authentication__XZuWe li {
    cursor: pointer;
    height: 55px;
    padding: 0;
    line-height: 55px;
}
.sideMenu_sideMenu__3qlqU .sideMenu_container__1LfqQ ul.sideMenu_menu__1q10J.sideMenu_profile__OPt45 {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.sideMenu_sideBarFrame__2ZBtW {
    padding: 10px;
    border-radius: 25px;
    border: 1px solid #dedede;
    box-shadow: #dedede;
}

.sideMenu_infoSideBar__fKEJ6 {
    text-align: center;
    font-weight: 600;
}

.sideMenu_sideMenu__3qlqU .sideMenu_container__1LfqQ ul.sideMenu_menu__1q10J.sideMenu_profile__OPt45 .sideMenu_nonEditable__3uRTH {
    margin-bottom: 30px;
}
.sideMenu_sideMenu__3qlqU .sideMenu_container__1LfqQ ul.sideMenu_menu__1q10J.sideMenu_profile__OPt45 .sideMenu_nonEditable__3uRTH p {
    margin: 5px 0;
}

.sideMenu_sideMenu__3qlqU .sideMenu_container__1LfqQ ul.sideMenu_menu__1q10J.sideMenu_profile__OPt45 .sideMenu_editable__1SHVC {
    padding-top: 10px;
}

.sideMenu_sideMenu__3qlqU .sideMenu_close__22KEi:hover {
    -webkit-animation: sideMenu_spin__133t9 .4s ease;
            animation: sideMenu_spin__133t9 .4s ease;
}

@-webkit-keyframes sideMenu_spin__133t9 {
    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
}

@keyframes sideMenu_spin__133t9 {
    100% {
        -webkit-transform: rotate(270deg);
        transform: rotate(270deg);
    }
}

/* Small screen */
.style_formField__38qHz {
    font-size: 15px !important; /* Don't make less than 16px to avoid auto-zoom on iPhone */
    color: rgb(33, 33, 33) !important;
    margin-top: 0 !important;
    margin-bottom: 15px !important;
    width: 100%;
    font-weight: 400;
    /*height: 3em;*/
}

.style_formField__38qHz label {
    /*color: rgb(77, 77, 77) !important;*/
    font-size: 15px;
    font-weight: 400;
    font-family: Montserrat, sans-serif !important;
}

.style_formField__38qHz fieldset {
    border-radius: 4px !important;
    /*border-color: rgba(86, 128, 237, .6);*/
    /*border-color: rgba(66, 66, 66, .2);*/
    /*border-color: transparent;*/
}

.style_formField__38qHz input {
    font-size: 18px;
    height: 1rem;
    font-weight: 400;
    font-family: Montserrat, sans-serif;
    color: rgb(33, 33, 33);
    background-color: #fff;
    border-radius: 9px !important;
}

.style_inputSideBar__1Yjbh input{
    height: 4rem !important;
}

form label {
    color: #666;
    font-weight: 600;
    font-size: 15px !important;
}

/* Big screen */
@media (min-width: 576px) {
    .style_formField__38qHz {
        font-size: 18px !important;
        /* margin-bottom: 1.2em; */
    }

    .style_formField__38qHz label {
        font-size: 18px;
    }

    form label {
        font-size: 17px !important;
    }
}

button.formButton,
label.formButton {
    font-family: Montserrat, Roboto, sans-serif;
    font-size: 1.05em;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    height: auto;
    padding: 10px 0;
    line-height: 24px;
    font-weight: 550;

    /*Variant 1*/
    /*background: transparent;*/
    /*border: 2px solid #3451b9;*/
    /*color: #3451b9;*/

    /*Variant 2*/
    color: #fff;

    text-transform: none;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.formButton.buttonSideBar {
    background-color: white;
    color: #627acc;
    height: 5vh;
}

.formButton.buttonSideBar:hover {
    background-color: #5372e4;
    color: white;
    height: 5vh;
}

button.whiteButton {
    background-color: #fff;
    color: #5372e4;
    border: 1px solid #5372e4;
}

button.fadingButton {
    opacity: 0.8;
    transition: opacity .3s ease-in-out;
}

button.fadingButton:hover {
    opacity: 1;
}

label.formButton.attachButton {
    font-size: 16px;
    line-height: 100%;
}

@media only screen and (max-width: 768px) {
    button.formButton, label.formButton {
        width: 100%;
    }

    label.formButton.attachButton {
        margin: 10px 0;
    }
}

button.formButton:hover,
label.formButton:hover {
    /*-webkit-box-shadow: 0 3px 10px rgba(112, 72, 232, .5);*/
    /*box-shadow: 0 3px 10px rgba(112, 72, 232, .5);*/
    box-shadow: 0 2px 10px rgba(0, 0, 0, .5);
}

label.formButton {
    cursor: pointer;
    text-align: center;
    display: inline-block;
}

button.formButton:hover,
label.formButton:hover {
    background: #5372e4;
    color: #ffffff;
}

button.formButton div span,
label.formButton div span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

button.formButton:disabled,
label.formButton:disabled {
    opacity: 0.5;
}

button.smallButton {
    font-size: 14px;
    /* height: 36px; */
    overflow: hidden;
    line-height: 18px;
    padding: 10px 0;
}

.formButton {
    font-size: 19px !important;
}

.buttons-wrapper {
    margin-top: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
}
.buttons-wrapper .formButton{
    margin: 10px !important;
    padding: 10px 20px;
}
/* Big screen */
@media only screen and (min-width: 576px) {
    button.formButton,
    label.formButton {
        font-size: 16px;
        line-height: 32px;
        padding: 10px 0;
    }

    button.smallButton {
        font-size: 16px;
        line-height: 24px;
    }
}

button.formButton {
    margin: 0 auto 15px auto;
    border-radius: 10px;
}

button.formButton.backButton {
    text-align: center;
    border: 2px solid #edf1f8;
    border-radius: 10px;
    background-color: #fff;
    box-shadow:0 6px 16px 0 rgba(0, 0, 0, 0.16);
    font-family: Montserrat, sans-serif;
    color: #819ab4;
    font-size: 20px;
    font-weight: 600;
}

button.formButton.socialButton {
    font-weight: 300;
    border-radius: 3px;
    width: 100%;
    padding: 8px;
    font-size: 16px;
}

button.formButton.socialButton:disabled {
    color: #fff;
}

button.formButton.socialButton.facebookButton {
    background-color: #3b5998;
}

button.formButton.socialButton.linkedInButton {
    background-color: #3395c4;
}

button.formButton.socialButton.emailButton {
    background-color: #555;
}

button.formButton.emailButton,
button.formButton.facebookButton,
.kep-login-facebook.metro{
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.emailButton {
    font-weight: normal !important;
}

.style_footer__13BX4 {
    padding: 0;
    text-align: right;
    margin-top: 50px;
    bottom: 0;
    position: absolute;
    width: 100%;
    left: 0
}

.style_linkArea__1liUD {
    padding-right: 100px;
}

.style_link__2rz9q {
    font-size: 16px;
    color: #999;
    /*padding: 0 10px;*/
    margin: 20px;
    text-decoration: none;
    display: inline-block;
}

.style_wedou_logo_footer__obwnM {
    max-width: 70px;
    max-height: 70px;
    display: inline-block;
    vertical-align: middle;
}

.style_login__2pB_Y {
    font-size: 16px;
    margin: 0px 0 20px 20px;
    text-decoration: underline;
    color: #5372e4;
    cursor: pointer;
    display: inline-block;
}

.style_link__2rz9q span {
    border-bottom: 1px solid #999;
}

.style_link__2rz9q:hover {
    border-bottom: none;
}

.style_personalFooter__1GZs5 {
    color: #fff;
    max-width: 100%;
    min-height: 200px;
    height: auto;
    padding: 0px 0 0px 0;
    font-size: 16px;
    font-weight: 400;
    z-index: 100;
    overflow: hidden;
    margin-left: 300px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4776e6+0,8e54e9+100 */
    /*background: #3451b9; !* Old browsers *!*/
    /*background: -moz-linear-gradient(top, #4776e6 0%, #8e54e9 100%); !* FF3.6-15 *!*/
    /*background: -webkit-linear-gradient(top, #4776e6 0%, #8e54e9 100%); !* Chrome10-25,Safari5.1-6 *!*/
    /*background: linear-gradient(to bottom, #4776e6 0%, #8e54e9 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!*/
    /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4776e6', endColorstr='#8e54e9', GradientType=0); !* IE6-9 *!*/

    /*Background-Colors Test*/
    /*-webkit-filter: contrast(80%) blur(0);*/
    /*filter: contrast(50%) blur(40);*/
    left: 0;
    width: 100%;
}

@media screen and (max-width: 1024px) {

    .style_personalFooter__1GZs5 {
        position: relative;
        left: -1px;
        margin-left: 1px;
    }
}

.style_personalFooter__1GZs5 > div {
    padding: 0 15px;
}

.style_personalFooter__1GZs5 a {
    font-size: 18px;
    color: #fff;
    text-decoration: none;
}

.style_personalFooter__1GZs5 a:hover {
    border-bottom: 1px solid black;
}

.style_personalFooter__1GZs5 .style_head__q_PUu {
    font-size: 24px;
    font-weight: 500;
}

.style_personalFooter__1GZs5 .style_copyright__2vbos {
    padding-top: 0px;
    text-align: center;
    font-size: 14px;
}

.style_personalFooter__1GZs5 .style_footerLogo__2nTu_ {
    height: 60px;
}

.style_NoUnderline__qHkqa a:hover {
    border-bottom: 0px solid white;
}

.style_NoUnderline__qHkqa li {
    list-style: none;
    margin: 5px 10px;
    display: inline-block;
    font-size: 30px;
    padding-left: 20px 10px;
    color: #fff;
    border: 0px solid #fff;
    border-radius: 50%;
    transition: .5s;
}

.style_NoUnderline__qHkqa li:hover {
    color: #292929;
    border: 0px solid #292929;
    transition: .5s;
}


.titleWrapper {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    padding: 0 15px !important;
    display: grid;
    -webkit-align-content: center;
            align-content: center;
}




@media only screen and (min-width: 568px) {
    button.MuiButton-root.loginLogoutButton:hover {
        border: 2px solid white;
    }
    button.MuiButton-root.loginLogoutButton {
        border: 2px solid white;
        border-radius: 100px;
        color: #fff;
        font-weight: 500;
        width: 200px;
        height: 37px;
        padding: 8px 16px;
        font-size: .675rem;
        /*border: 1px solid #fff;*/
        margin-top: 15px;
        margin-bottom: 15px;
    }
}
@media only screen and (max-width: 568px) {
    button.MuiButton-root.loginLogoutButton{
        height: 70px;
        width: 70px;
        border-radius: 100px;
    }
    button.MuiButton-root.loginLogoutButton {
        padding: 2px;
        text-align: center;
    }
}

@media only screen and (max-width: 360px) {
    button.MuiButton-root.loginLogoutButton {
        padding: 2px;
    }
    button.MuiButton-root.loginLogoutButton span.MuiButton-label span {
        padding: 0;
    }
}

/*.headerTitle h1 {*/
.headerTitle {
    font-size: 34px;
    text-align: center;
}


@media only screen and (max-width: 400px) {
    .headerTitle {
        font-size: 24px;
        font-weight: 500;
        text-align: center;
    }
}

.headerTitle p {
    -webkit-margin-before: 0.4rem;
            margin-block-start: 0.4rem;
    -webkit-margin-after: 0.4rem;
            margin-block-end: 0.4rem;
}

.trianglePhone {
    text-align: center;
    margin-top: 5vh;
    padding: 10px 16px;
    border: 2px solid #edf1f8;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 16%);
    font-family: Montserrat, sans-serif;
    color: #819ab4;
    font-size: 16px;
    font-weight: 400;
}


.backButton button {
    width: 44px !important;
    height: 44px !important;
    line-height: 44px !important;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4776e6+0,8e54e9+100 */
    /* background: #fff; Old browsers */
    color: yellow;
    /* background: -moz-linear-gradient(top, #4776e6 0%, #8e54e9 100%); FF3.6-15 */
    /* background: -webkit-linear-gradient(top, #4776e6 0%,#8e54e9 100%); Chrome10-25,Safari5.1-6 */
    /* background: linear-gradient(to bottom, #4776e6 0%,#8e54e9 100%); W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4776e6', endColorstr='#8e54e9',GradientType=0 ); IE6-9 */
}

.backButton button {
    background-color: white !important;
}

.backButton button span {
    line-height: 44px !important;
    color: rgb(142, 142, 142) !important;
}

.MuiButton-root.headerBackButton {
    background-color: white;
    color: #5372e4;
    border-radius: 0;
    border: 0.5px solid #5372e4;
    padding: 8px 16px;
    height: 37px;
    font-size: 0.675rem;
    margin-top: 15px;
}

.userName {
    text-align: right;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
    font-weight: 400;
    margin-top: 15px;
}

.userName a {
    cursor: pointer;
}

.accountContainer {
    width: 100%;
    text-align: center;
}
/*.accountContainer .fa-user-o {*/
/*    top: 0;*/
/*    right: 0;*/
/*    z-index: 1;*/
/*    margin-top: 20px;*/
/*    font-size: 36px;*/
/*    font-weight: bold;*/
/*    cursor: pointer;*/
/*}*/

h3.title {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    font-weight: 600 !important;
    color: #000 !important;
}

/* Small screen */
h3.title, h3.title span {
    font-size: 1.1rem !important;
    text-align: center;
}

.avatarBig {
    display: none !important;
}

.bravoAvatar {
    width: 72px;
    height: 72px;
}

.avatarWrapper {
    text-align: left !important;
    /* min-width: 110px; */
}

/* Big screen */
@media only screen and (min-width: 576px) {
    .backButton button {
        width: 64px !important;
        height: 64px !important;
        line-height: 64px !important;
    }

    .backButton button span {
        line-height: 64px !important;
    }

    .userName {
        font-size: 15px;
    }

    h3.title, h3.title span {
        font-size: 1.4rem !important;
        text-align: left;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    /*.titleWrapper {*/
    /*border: 2px solid rgb(92, 71, 185);*/
    /*}*/
}
.profession-subtitle {
    color: #fff;
    font-size: 24px;
    text-align: center;
}
.progressBar {
    display: grid;
    grid-template-columns: 1fr;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
}

.arrow, .arrowMobile {
    position: fixed;
    display: none;
}

@media only screen and (min-width: 480px) and (min-height: 950px) {
    .arrowMobile {
        display: none;
    }

    .arrow {
        display: block;
        height: 700px;
        right: 205px;
        margin-top: 30px;
    }
}

@media only screen and (min-width: 480px) and (min-height: 730px)  and (max-height: 950px) {
    .arrowMobile {
        display: none;
    }

    .arrow {
        display: block;
        height: 495px;
        right: 288px;
        margin-top: 34px;
    }
}

@media only screen and (min-width: 480px) and (max-height: 730px)  and (min-height: 450px) {
    .arrowMobile {
        display: none;
    }

    .arrow {
        display: block;
        height: 405px;
        right: 233px;
        margin-top: 13px;
    }
}

@media only screen and (max-width: 480px) and (min-height: 700px) {
    .arrowMobile {
        display: block;
        height: 400px;
        right: 47px;
        margin-top: 57px;
    }

    .arrow {
        display: none;
    }
}

@media only screen and (max-width: 480px) and (max-height: 700px) {
    .arrowMobile {
        display: block;
        height: 300px;
        right: 47px;
        margin-top: 57px;
    }

    .arrow {
        display: none;
    }
}

@media only screen and (min-width: 768px) {
    .titleWrapper {
        margin-left: -15px;
        /*-webkit-border-radius: 20px;*/
        /*-webkit-border-bottom-left-radius: 0;*/
        /*-moz-border-radius: 20px;*/
        /*-moz-border-radius-bottomleft: 0;*/
        /*border-radius: 20px;*/
        /*border-bottom-left-radius: 0;*/
    }

    .progressContainer {
        width: 60%;
        margin: auto;
    }

    .progressContainer div {
        top: 30px
    }

    .progressBar div {
        justify-self: center;
        width: 700px;
    }

    .avatarWrapper {
        text-align: right !important;
    }

    .avatarBig {
        display: -webkit-inline-flex !important;
        display: inline-flex !important;
    }

    .avatarSmall {
        display: none !important;
    }

}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .progressBar div {
        justify-self: center;
        width: 900px;
    }
}

h1 {
    font-size: 40px;
    text-align: center;
    white-space: nowrap;
}

.style_row__1iF2Y {
    margin-top: 30px;
    margin-bottom: 0;
}

.style_result__1aC-J {
    font-size: 17px;
    line-height: 1.6;
    margin-top: 2rem;
}

.style_stepSubtitle__29Fwk h4 {
    font-size: 1.4em;
    font-weight: 300;
}

@media only screen and (max-width: 2200px) {
    .style_title_dynamic__3NJ2B {
      height: 40px;
    }
  }

.style_headerTitle__YtsEA {
    font-size: 30px;
}

.style_registerSection__3XxFQ p {
    font-size: 18px;
    text-align: center;
}

.style_acceptTerms__1AyvN {
    line-height: 1.5;
    margin-top: .5em;
    font-size: 10px !important;
    text-align: justify;
}
.style_acceptTerms__1AyvN a {
    font-weight: bold;
}

.style_acceptCheckbox__1VrIu {
    float: left;
}

.style_odometerWrapper__xTOYU {
    font-size: 42px;
    margin: 10px;
}

.style_odometerWrapperSmall__Lsdsu {
    font-size: 30px;
    margin-top: 0;
}

.style_perMonth__1MK3O {
    margin-top: 0;
    /*padding-left: 15px;*/
    font-size: 16px;
    font-weight: 400;
    color: #000000;
}

.style_compareText__1cP0A {
    text-align: center;
    line-height: 1.5;
    margin-top: 35px;
}

.style_compareText__1cP0A button {
    font-family: Montserrat, Roboto, sans-serif;
    font-size: 16px;
    overflow: hidden;
    height: auto;
    padding: 7px 15px;
    margin-top: 10px;
    line-height: 24px;
    font-weight: 400;
    color: #fff;
    text-transform: none;
    margin-bottom: 15px;
    border-radius: 0;
    display: inline-block;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.style_compareText__1cP0A button:hover {
    background: #5372e4;
    text-decoration: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
}

.style_verticalCenter__pwQVn {
    margin-top: 5vh;
}

.style_title__a3XHs {
    display: block;
    text-align: center;
    margin-bottom: 10px;
    padding-top: 40px;
}

.style_preButtonText__fI1No {
    font-size: 14px;
    text-align: center;
    color: #75ACBA;
    line-height: 1;
}
.style_buttonImage__1AdtR {
    width: 100%;
    height: 47px;
    text-align: center;
    background: #034EA2;
    border: 1px solid #1B1464;
    border-bottom: 4px solid #1B1464;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}
.style_buttonImage__1AdtR:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .5);
}
.style_svg__3o2Cn {
    height: 49px;
    cursor: pointer;
}
.style_whatIsFranceConnect__UNNp9 {
    font-size: 13px;
    width: 100%;
    text-align: center;
    display: inline-block;
}

@media only screen and (max-width: 937px) {
    .style_preButtonText__fI1No {
        line-height: 19px;
    }
}
@media only screen and (max-width: 843px) {
    .style_preButtonText__fI1No {
        padding: 0 33px;
        line-height: 18px;
        /*letter-spacing: 1.2px;*/
    }
}
@media only screen and (max-width: 768px) {
    .style_preButtonText__fI1No {
        font-size: 15px;
    }
    .style_buttonImage__1AdtR {
        height: 44px;
    }

    .style_svg__3o2Cn {
        height: 45px;
    }
}

.style_sideEar__2Kz9o.style_mobile__3e5dN {
    top: 300px;
    right: 0;
    width: 400px;
    min-height: 150px;
    margin-right: -370px;
    color: #ffffff;
    position: fixed;
    cursor: pointer;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    transition: margin-right .4s ease-out;
}

@media screen and (max-width: 380px) {
    .style_sideEar__2Kz9o.style_mobile__3e5dN {
        width: 350px;
        margin-right: -320px;
    }

    div.style_callBackForm__Xmnce {
        width: 350px;
        margin-right: -360px;
    }

    .style_sideEar__2Kz9o.style_mobile__3e5dN .style_sideEarContent__36m1K .style_method__349p-.style_chat__1QxHn p {
        font-size: 15px;
    }
}

@media screen and (min-width: 320px) and (max-width: 355px) {
    .style_sideEar__2Kz9o.style_mobile__3e5dN {
        width: 320px;
        margin-right: -290px;
    }

    div.style_callBackForm__Xmnce {
        width: 350px;
        margin-right: -360px;
    }
}

@media screen and (min-width: 376px) {
    .style_sideEar__2Kz9o.style_mobile__3e5dN .style_sideEarContent__36m1K .style_method__349p- p {
        letter-spacing: 1px;
    }
}

@media screen and (max-height: 460px) {
    .style_sideEar__2Kz9o.style_mobile__3e5dN .style_sideEar__2Kz9o.style_mobile__3e5dN {
        top: 140px;
    }

    div.style_callBackForm__Xmnce {
        top: 30px;
    }

}

.style_sideEar__2Kz9o.style_mobile__3e5dN.style_active__3xW5y {
    margin-right: 0;
}

.style_sideEar__2Kz9o.style_mobile__3e5dN:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
}

.style_sideEar__2Kz9o.style_mobile__3e5dN .style_earText__1TSPw {
    border-radius: 0 0 3px 3px;
    background: #5372e4;
    line-height: 30px;
    width: 150px;
    text-align: center;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    margin: 60px 0 0 -60px;
    border-top: 1px solid rgba(250, 250, 250, 0.3);
}

.style_sideEar__2Kz9o.style_mobile__3e5dN .style_sideEarContent__36m1K {
    padding: 0 20px;
    margin-left: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    background: #ffffff;
    height: 150px;
    margin-top: -91px;
    -webkit-justify-content: center;
            justify-content: center;
    vertical-align: middle;
    box-sizing: border-box;
    background: hsla(0, 0%, 100%, .4);
    box-shadow: 0 10px 24px rgba(8, 15, 26, .16);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.style_sideEar__2Kz9o.style_mobile__3e5dN .style_sideEarContent__36m1K .style_method__349p- {
    color: #5372e4;
    margin: 0 auto;
    -webkit-flex: 1 1;
            flex: 1 1;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    vertical-align: middle;
}

.style_sideEar__2Kz9o.style_mobile__3e5dN .style_sideEarContent__36m1K .style_method__349p- p {
    margin-bottom: 0;
}

.style_sideEar__2Kz9o .style_svgWrap__3Yu4R {
    width: 26px;
    height: 26px;
    padding: 16px 16px 14px 16px;
    border-radius: 50px;
    /*background: #3451b9;*/
    /*margin: 40px auto 0 auto;*/
    margin: 0 auto;
    display: block;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.style_sideEar__2Kz9o .style_svgWrap__3Yu4R:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
}

.style_sideEar__2Kz9o .style_chat__1QxHn .style_svgWrap__3Yu4R {
    color: rgb(0, 125, 252);
    background: linear-gradient(135deg, rgb(42, 39, 218), rgb(0, 204, 255));
}

.style_sideEar__2Kz9o .style_chatIcon__22S1e {
    width: 24px;
    height: 24px;
}

.style_sideEar__2Kz9o .style_callBack__3QJV4 .style_svgWrap__3Yu4R {
    background: #5372e4;
}

.style_sideEar__2Kz9o .style_call__2Q3Jh .style_svgWrap__3Yu4R {
    background: #4CD964;
}

.style_callBackForm__Xmnce {
    width: 450px;
    max-width: 100%;
}

.style_sideEar__2Kz9o .style_call__2Q3Jh i,
.style_sideEar__2Kz9o .style_callBack__3QJV4 i {
    color: #ffffff;
}

.style_sideEar__2Kz9o .style_callBack__3QJV4 i {
    font-size: 26px;
}

.style_sideEar__2Kz9o .style_call__2Q3Jh i {
    font-size: 23px;
}

.style_callBackForm__Xmnce {
    box-sizing: border-box;
    position: fixed;
    top: 200px;
    right: 0;
    width: 400px;
    height: 95px;
    background: white;
    background: hsla(0, 0%, 100%, .4);
    /*box-shadow: 0 10px 24px rgba(8, 15, 26, .16);*/
    box-shadow: 0 2px 10px rgba(0, 0, 0, .4);

    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border-radius: 0 0 3px 3px;
    transition: margin-right .4s ease-out;
    margin-right: -410px;
    z-index: 1000;
}

.style_callBackForm__Xmnce.style_active__3xW5y {
    margin-right: 0;
}

button.style_submitCallback__29Mo9 {
    padding: 0;
    background: #5372e4;
    color: #ffffff;
    font-size: 15px;
    margin: 42px -2% 1.5%;
    height: 50px;
    min-width: 30%;
    display: -webkit-flex;
    display: flex;
}

button.style_submitCallback__29Mo9.style_green__3-cRw {
    background: #4CD964;
}

.style_submitCallInput__29GCM {
    height: 30px;
    border: 1px solid rgba(118, 118, 118, 0.3);
    font-family: Montserrat, sans-serif;
    border-radius: 3px;
    /*display: inline-block;*/
    /*margin: 30px 2.5% 0 2.5%;*/
    outline: none;
    /*margin: 20px 1.5% 0 1.5%!important;*/
    margin: 5px 1.5% 0 1.5% !important;
    display: inline-block !important;
}

.style_submitCallInput__29GCM .style_MuiInput-formControl__bLcTu {
    margin-top: 0 !important;
}

input.style_submitCallInput__29GCM:placeholder {
    margin-left: 5px;
}

input.style_submitCallInput__29GCM:active,
input.style_submitCallInput__29GCM:focus {
    border: 1px solid #5372e4;
}

/*DESKTOP*/
.style_callBackForm__Xmnce.style_desktop__Hmy7P {
    top: auto;
    bottom: 155px;
    right: 20px;
    margin-right: -440px;
    z-index: 9999999989;
}

.style_callBackForm__Xmnce.style_desktop__Hmy7P.style_active__3xW5y {
    margin: 0;
}

.style_sideEar__2Kz9o.style_desktop__Hmy7P {
    /*bottom: 30px;*/
    bottom: 50px;
    right: 20px;
    min-width: 200px;
    height: 75px;
    /*margin-right: -370px;*/
    color: #ffffff;
    position: fixed;
    cursor: pointer;
    /*background: #3451b9;*/
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    /*transition: margin-right .4s ease-out;*/
    border-radius: 1000px;
    transition: width .6s ease-out;
    background: hsla(0, 0%, 100%, .4);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 9999999989;
    display: none;
}

.style_sideEar__2Kz9o.style_desktop__Hmy7P .style_sideEarContent__36m1K {
    width: 0;
    height: 0;
    margin: 0;
    box-sizing: border-box;
    /*overflow: hidden;*/
    float: left;
}

.style_sideEar__2Kz9o.style_desktop__Hmy7P .style_earText__1TSPw {
    text-align: center;
    height: 75px;
    width: 200px;
    line-height: 75px;
    margin: 0;
    float: left;
    border-radius: 1000px;
    background: #4cd964;
    font-size: 18px;
    font-weight: 500;
}

.style_sideEar__2Kz9o.style_desktop__Hmy7P .style_earText__1TSPw * {
    vertical-align: middle;
}

.style_sideEar__2Kz9o.style_desktop__Hmy7P .style_earText__1TSPw .style_question__15DoV {
    height: 28px;
    font-size: 26px;
    margin-right: 7px;
}

.style_sideEar__2Kz9o.style_mobile__3e5dN .style_earText__1TSPw .style_question__15DoV {
    margin-right: 7px;
}

.style_sideEar__2Kz9o.style_desktop__Hmy7P .style_sideEarContent__36m1K {
    display: -webkit-flex;
    display: flex;
    width: 280px;
    -webkit-flex-direction: row;
            flex-direction: row;
    height: 75px;
    -webkit-justify-content: center;
            justify-content: center;
    vertical-align: middle;
    background: transparent;
    box-sizing: border-box;
    opacity: 0;
    margin-right: -280px;
    transition: margin .6s ease-out, opacity .4s ease-out;
}

.style_sideEar__2Kz9o.style_desktop__Hmy7P.style_active__3xW5y .style_sideEarContent__36m1K {
    margin-right: 0;
    padding: 0 20px;
    opacity: 1;
}

.style_sideEar__2Kz9o.style_desktop__Hmy7P .style_sideEarContent__36m1K .style_method__349p- {
    color: #5372e4;
    margin: 0 auto;
    -webkit-flex: 1 1;
            flex: 1 1;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    vertical-align: middle;
}

.style_earPhone__1vvD9 {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 20px;
    background-color: #5372e4;
    color: white !important;
    text-decoration: none;
}


.style_conseil_btn__3kBky {
    position: fixed;
    right: 10px;
    bottom: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 60px;
    height: 60px;
    clear: right;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    z-index: 1000000;
    border-radius: 50px;
    /*background-color: #5372e4;*/
    /*background: linear-gradient(180deg, #5372e4, rgb(63, 165, 238));*/
    box-shadow: 0 3px 10px 0 rgb(30 32 83 / 29%);
}

.style_conseil_btn__3kBky h2 {
    font-weight: 600;
    color: white;
    line-height: 100%;
}

.style_conseilButton__1FiQ5 {
    background-color: white;
    font-weight: 600;
    width: 215px;
    border-radius: 10px;
    padding: 10px;
}

.style_conseilFrame__1o9v_ {
    border-radius: 10px;
    /*padding: 12px;*/
    margin: 5px 15px 10px 15px;
    /*border: 1px solid rgb(0 0 0 / 6%);*/
    /*box-shadow: 0 1px 2px rgb(0 0 0 / 20%);*/
    /*-webkit-box-shadow: 0 1px 2px rgb(0 0 0 / 20%);*/
}

.style_conseilFrame__1o9v_ p,
.style_conseilFrame__1o9v_ svg {
    color: #fff;
}

.style_conseilFrame__1o9v_:last-child {
    margin-bottom: 30px;
}

.style_sideEar__2Kz9o.style_desktop__Hmy7P .style_sideEarContent__36m1K .style_method__349p- p {
    margin-bottom: 0;
}

.style_sideEar__2Kz9o.style_desktop__Hmy7P .style_method__349p- p {
    /*display: none;*/
    /*margin-top: -80px;*/
    margin-top: 0;
    opacity: 0;

    position: absolute;
    width: 90%;
    background: white;
    padding: 10px 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
    border-radius: 10px;
    font-size: 15px;
    transition: margin .2s ease-out, opacity .2s ease-out;
}

.style_sideEar__2Kz9o.style_desktop__Hmy7P .style_method__349p- p:after {
    width: 0;
    height: 0;
    content: "";
    position: absolute;
    display: inline-block;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #ffffff;
    bottom: 0;
    margin-bottom: -8px;
    left: 50%;
    margin-left: -4px;
}

.style_sideEar__2Kz9o.style_desktop__Hmy7P .style_method__349p-:hover p {
    margin-top: -80px;
    opacity: 1;
}

.style_contactAction__2hdJ- {
    /*color: #262247;*/
    width: 215px;
    margin: 5px auto 15px auto;
    color: #fff;
    text-align: left;
}

.style_contactAction__2hdJ- svg {
    width: 21px;
    vertical-align: middle;
}

/*.sideEar.desktop.active .method p {*/
/*    margin-top: -80px;*/
/*    opacity: 1;*/
/*}*/

button.formButton {
    font-family: Montserrat, Roboto, sans-serif;
    font-size: 16px;
    overflow: hidden;
    height: auto;
    padding: 10px 0;
    line-height: 24px;
    font-weight: 400;
    background: #5372e4;
    color: #fff;
    text-transform: none;
    margin-bottom: 15px;
    border-radius: 4px;
}

button.formButton div span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

button.formButton:disabled {
    opacity: 0.5;
    color: #ffffff;
    cursor: not-allowed;
}

button.smallButton {
    font-size: 14px;
    /* height: 36px; */
    overflow: hidden;
    line-height: 18px;
    padding: 10px 0;
}

/* Big screen */
@media only screen and (min-width: 576px) {
    button.formButton {
        font-size: 16px;
        /* height: 64px; */
        line-height: 32px;
        padding: 10px 0;
        min-width: 80%;
    }

    button.smallButton {
        font-size: 16px;
        /* height: 42px; */
        line-height: 24px;
    }
}

button.formButton.socialButton {
    font-weight: 300;
    border-radius: 3px;
    width: 100%;
    padding: 8px;
    font-size: 16px;
}

button.formButton.socialButton:disabled {
    color: #fff;
}

button.formButton.socialButton.facebookButton {
    background-color: #3b5998;
}

button.formButton.socialButton.linkedInButton {
    background-color: #3395c4;
}

button.formButton.socialButton.emailButton {
    background-color: #555;
}

.backButton {
    background-color: red;
}

.style_saveProgressWrapper__DRlzW {
    height: 200px;
    position: fixed;
    right: 30px;
    bottom: 30px;
    z-index: 9999999991;
    /*display: flex;*/
    /*flex-grow: 1;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
    /*align-content: center;*/
    /*justify-content: center;*/
    /*vertical-align: middle;*/
}

.style_saveProgressWrapper__DRlzW.style_expanded__fz0Iy {
    width: 200px;
}

.style_saveProgressButton__25XX6 {
    width: 220px;
    text-align: left;
    height: 20px;
    line-height: 15px;
    padding: 15px 5px;
    border-style: solid;
    border-width: 1px;
    border-color: hsla(0, 0%, 100%, 0.18);
    border-radius: 50px;
    color: white;
}

.style_saveProgressButton__25XX6:hover {
    cursor: pointer;
    background-color: white;
    color: black;
}

.style_saveProgressIcon__11xvk {
    color: white;
}

.style_updateWrapper__IOiX9 {
    width: 100%;
    bottom: 60px;
    position: absolute;
    /*background: #ffffff;*/
    /*-webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);*/
    /*box-shadow: 0 2px 10px rgba(0, 0, 0, .2);*/
    /*padding: 20px;*/
}

.style_updateProgress__Lp6za {
    font-size: 15px;
    background: #5372e4;
    margin: 5px auto;
    color: white;
    text-align: center;
}

.style_updateProgress__Lp6za,
.style_updateAndSend__327ln {
    font-size: 15px;
    background: #5372e4;
    margin: 5px auto;
    color: white;
    width: 100%;
    display: block;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.style_updateProgress__Lp6za:hover,
.style_updateAndSend__327ln:hover {
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .4);
}

.style_updateProgress__Lp6za.style_disabled__AGPGO,
.style_updateAndSend__327ln.style_disabled__AGPGO {
    opacity: .8;
    cursor: not-allowed;
}

.style_button__2qYuo {
    margin-top: 30px;
    width: 100%;
    min-width: 200px;
    cursor: pointer;
    height: 53px;
    background-color: #5372e4;
    color: #ffffff;
    border: 2px solid #5372e4;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
}


.style_updateAndSend2__2o3mc.style_disabled__AGPGO,
.style_updateAndSend2__2o3mc.style_disabled__AGPGO:hover {
    background: #4CD964 !important;
    cursor: not-allowed;
    opacity: .8;
}

.style_fc_logo__3zDUV {
    height: 80px;
    /*margin: 50px auto 0 auto;*/
    /*display: block;*/
    margin: 30px auto 0 auto;
    display: block;
    vertical-align: middle;
}

.style_bpi_france__g-lu- {
    height: 65px;
    /*margin: 50px auto 0 auto;*/
    /*display: block;*/
    margin: 30px auto 0 auto;
    display: block;
    vertical-align: middle;
}

.style_loaderPages__2W9jt {
    font-family: Poppins-Light;
    margin-left: 25px;
    display: block;
    font-size: 13px;
    font-weight: 0;
    text-transform: uppercase;
    margin-bottom: 21px;
    cursor: pointer;
}

.style_phoneNumber__2KrS7 {
    text-align: left;
    height: 20px;
    line-height: 15px;
    padding: 15px 5px;
    width:220px;
    color: #FE7A59;
    margin:auto !important;
    margin-bottom: 15px !important;
}

.style_loader__3J2A_ {
    margin-left: 20px;
    margin-top: 10px;
    text-align: left;
    margin-bottom: 30px;
    width: 12px;
    border-radius: 15px;
    height: 200px;
}

.style_calendarModal___TY_U {
    width: 100%;
}

.style_sidebar_title__3pEfi {
    text-align: center;
    font-weight: 600;
    font-size: 2vh;
    color: #4c4d76;
    margin-top: 1vh;
}

.style_sidebar_list__Q4dnh {
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
    color: black;
}

 .style_inputSideBar__2ATtL {
    color: #627acc !important;
    text-align: right;
}

.style_besoin__30XAc {
    width: 220px;
    text-align: left;
    height: 20px;
    line-height: 15px;
    padding: 15px 5px;
    border-style: solid;
    border-width: 1px;
    border-color: hsla(0, 0%, 100%, 0.18);
    border-radius: 50px;
    color:white;
    margin: auto !important;
    margin-bottom: 15px !important;
}

.style_besoin__30XAc:hover {
    cursor: pointer;
    background-color: white;
    text-align: left;
    line-height: 20px;
    border-radius: 25px;
    color:black;
}

.style_wedou_logo__11BNl {
    max-width: 234px;
    max-height: 104px;
    cursor: pointer;
    /*margin: 45px auto 0 auto;*/
    /*display: block;*/
    /*margin: 50px auto 0 auto;*/
    /*padding-right: 35px;*/
    display: inline-block;
    vertical-align: middle;
}

.style_sidebarList__APGTb {
    color: white;
}

.body-info {
    padding-top: 10px;
    width: 400px;
}

.errors {
    color: red;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 10px;
    font-weight: bold;
}

.form1Mobile {
    border: 2px solid #5372e4;
    color: #5372e4;
    background-color: white;
    width: 32%;
    margin: auto;
    margin-left: 1%;
    border-radius: 9px;
    margin-top: 10px;
    min-height: 3.4vh;
    font-size: 13px;
    text-align: center;
}

.white-button {
    width: 100%;
    background-color: white;
    color: #5372e4;
    font-size: 22px;
    font-weight: 500;
    padding: 10px;
    cursor: pointer;
    border-radius: 15px;
    border: 1px solid #5372e4;
}

.white-button:hover {
    background-color: #5372e4;
    color: white;
}

.wedouText {
    color: #75ACBA;
}

.containerRendezVous {
    /*max-width: 800px;*/
    margin: 0 auto;
}

@media screen and (max-width: 768px) {
    .containerRendezVous {
        padding: 0;
    }

    .calendarRow, .calendarCol {
        padding: 3px !important;
    }
}

.calendarContainer {
    border: 2px solid #e5eaf2;
    /*box-shadow: 0 4px 8px 0 #e5eaf2, 0 6px 40px 0 #f0f0fb;*/
    border-radius: 20px;
    background: #fff;
}

.container {
    border-radius: 4px;
    border: 1px solid #f3f3f1;
    /* margin-top: 10px; */
    background-color: white;
}

.title-template {
    border-bottom: 2px solid black;
    padding: 0 0 5px 10px;
    margin-bottom: 20px;
}

.img-template {
    border: 1px solid #555;
    margin: 10px;
    height: auto;
}

.title_calendar {
    font-size: 24px;
    line-height: 26px;
}

@media only screen and (max-width: 800px) {
    .title_calendar {
        font-size: 16px;
    }
}

.template {
    margin-bottom: 20px;
}

.containerMobile {
    width: 90%;
    margin: auto;
    border-radius: 4px;
    border: 1px solid #f3f3f1;
    margin-top: 30px;
    background-color: white;
}

.date {
    margin-left: 70px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px;
}

.form1 {
    border: 2px solid #5372e4;
    color: #5372e4;
    background-color: white;
    width: 25%;
    margin: auto;
    border-radius: 9px;
    margin-top: 10px;
    min-height: 3.4vh;
    font-size: 16px;
    text-align: center;
    padding: 2px 5px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.divForm {
    overflow: auto;
    max-height: 360px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.divFormMobile {
    /*height: 20vh;*/
}

#window-button {
    font-size: 16px !important;
    font-family: Montserrat, sans-serif;
    color: white;
    border-radius: 5px;
    font-weight: 500;
}

.form1:hover {
    background-color: #5372e4;
    color: white;
}

/* .form1--active {
    background: #1087ff;
    color: white;
  }
  .form1--active:enabled:hover,
  .form1--active:enabled:focus {
    background: #1087ff;
  }
.form1:enabled:hover,
.form1:enabled:focus {
  background-color: #1087ff;
} */

.image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 4em;
    height: 4em;
    padding-top: 25px;
    margin-bottom: 10px;
}

.rendez-vous {
    line-height: 1.3;
    font-weight: bold;
    font-size: 18px;
    color: #5380ed;
}

.label {
    text-align: center;
    font-size: 16px;
    margin-top: 10px;
}

.top-title {
    font-weight: bold;
    line-height: 1.4;
    font-size: 18px;
}

.flex {
    display: -webkit-flex;
    display: flex;
    margin: 10px;
}

.calendar.react-calendar {
    max-height: 320px;
    max-width: 400px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    border-color: violet;
}

.calendar.react-calendar .react-calendar__tile {
    padding: 10px 5px;
}

#simulation {
    margin-top: 10px;
    margin-bottom: 0px;
}

.body {
    height: 46vh;
    width: 400px;
    background-color: white;
    margin: auto;
}

.text {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    margin-bottom: 3px;
    font-weight: bold;
}

.label-calendar {
    margin-top: 10px;
    font-size: 18px;
    color: #5d5d5d;
    letter-spacing: 1px;
    line-height: 25px;
}

#suivant-job {
    width: 240px;
    color: white;
}

.title-modal {
    text-align: center;
    margin-bottom: 20px;
    color: #18143b;
    font-size: 1.5em;
    font-weight: 500;
}

.modal-field {
    width: 240px;
}

.nested-label {
    margin-top: 9px;
    font-weight: bold;
}

.modal-title {
    margin-top: 5px;
}

#hours_container,
#minutes_container {
    height: 100%;
    width: 100%;
    overflow: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}

#hours_track,
#minutes_track {
    height: 245px;
    width: 50%;
    overflow: overlay;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    z-index: 100;
}

#hours_track::-webkit-scrollbar,
#minutes_track::-webkit-scrollbar {
    display: none;
}

#hours_track,
#minutes_track {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

#hours_track .page,
#minutes_track .page {
    height: 35px;
    line-height: 35px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    cursor: pointer;
    /*color: rgba(0, 0, 0, 0.54);*/
    color: #000;
}

.selected span {
    color: #fff !important;
}

.containerRendezVous #hours_track .page.selected,
.containerRendezVous #minutes_track .page.selected,
.container #hours_track .page.selected,
.container #minutes_track .page.selected {
    color: #fff;
    font-weight: 500;
}

#hours_track .page {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    padding-right: 7px;
    margin: 0 7px;
}

#minutes_track .page {
    -webkit-justify-content: start;
            justify-content: start;
    padding-left: 14px;
}

.dummy-content {
    height: 35px;
}

.scroll_middle_selector {
    position: absolute;
    width: 100%;
    height: 35px;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    text-align: center;
    color: #007aff;
    line-height: 35px;
    font-weight: 600;
    z-index: 100;
}

.scroll_middle_selector_hours,
.scroll_middle_selector_minutes {
    width: 75px;
    height: 100%;
    /*background: rgba(100,100,100,0.2);*/
    background: rgba(255, 255, 255, 0.7);
    border-radius: 8px;
    margin: 0 2px;
    display: inline-block;
    vertical-align: middle;
}

.scroll_middle_selector_minutes {
    margin-left: 2px;
}

.scroll_middle_selector_dots {
    display: inline-block;
    vertical-align: middle;
    width: 8px;
    text-align: center;
    color: #fff;
}

.minutes-header {
    font-size: 15px;
    margin: 0;
}

.container h4,
.container .minutes-header,
.container .scroll_middle_selector_dots {
    color: #5380ed;
}

.container .scroll_middle_selector_hours,
.container .scroll_middle_selector_minutes {
    background: rgba(100, 100, 100, 0.2);
}

.container #hours_track .page,
.container #minutes_track .page {
    color: rgba(0, 0, 0, 0.54);
}

.containerRendezVous h4,
.containerRendezVous .minutes-header {
    color: #75ACBA;
}

#hours_track .page.selected:after {
    content: "h";
}

#hours_track.bg .page.selected:after {
    content: "ч";
}

#minutes_track .page.selected:after {
    content: "m";
}

#minutes_track.bg .page.selected:after {
    content: "м";
}

@media only screen and (min-width: 768px) {
    .minutes-header {

        height: 45px;
    }
}

@media only screen and (max-width: 768px) {
    .body-info {
        width: auto;
    }

    .minutes-header {

        height: 15px;
    }

    .calendar-wrapper {
        margin-top: 60px;
    }

    #hours_track,
    #minutes_track {
        height: 175px;
    }
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  border: 0px solid #f3f3f1;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: -webkit-flex;
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
  text-decoration: none;
}

.react-calendar__navigation__next-button {
  font-size: 30px !important;
}

.react-calendar__navigation__prev-button {
  font-size: 30px !important;
}

.react-calendar__navigation__next2-button{
  display: none;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar button {
  margin: 0;
  border-radius: 8px;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  font-size: 20px;
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
  font-size: 0.8em;
}
/* //remove dots */
.react-calendar__month-view__weekdays__weekday {
  font-size: 14px;
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #18143b;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 2.5vh 0.5em;
  font-size: 16px;
  background: none;
}
.react-calendar__tile:disabled {
  color: #dce4eb;
  background-color: white;
}
/* put border, on hover on click -> #438cd2 */
/*  */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
border: 2px solid #5372e4;
margin: -2px 0 -2px 0;
background-color: white;
-webkit-transform: scale(.95);
        transform: scale(.95);
transition-duration: .15s;
transition-property: opacity,-webkit-transform;
transition-property: transform,opacity;
transition-property: transform,opacity,-webkit-transform;
}
.react-calendar__tile--now {
  color: #ffbc42 !important;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #3451b9;
}
.react-calendar__tile--active {
  background: #3451b9;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #3451b9;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.row {
    padding-left: 30px;
    padding-right: 30px;
}

.creditsSubtitle h3 {
    text-align: center;
    font-weight: normal;
}

.Mui-focused fieldset legend,
.MuiFormLabel-filled + div fieldset legend {
    padding-right: 15px !important;
}

.creditsColumn {
    text-align: center;
}

.creditsBlock {
    text-align: left;
    margin: 10px 0 30px 0;
}

.creditsBlock h4 {
    -webkit-margin-before: 0.8rem !important;
            margin-block-start: 0.8rem !important;
    -webkit-margin-after: 0;
            margin-block-end: 0;
}

.creditsBlock label {
    width: 100%;
    text-align: center;
    display: inline-block;
}

.creditsBlock img {
    width: 80px;
    cursor: pointer;
    display: inline-block;
}

/* .creditsBlock .inactive { */
/* opacity: 0.5; */
/* border: none; */
/* } */
.creditsBlock .wrapper {
    max-width: 7rem;
    margin: 0 auto;
    padding-bottom: 5px;
}

.creditsBlock .active .wrapper {
    border-bottom: 7px solid #5372e4;
}

.fieldsHeader {
    margin-top: -35px;
}

.paddingBottom {
    padding-bottom: 35px;
}

@media only screen and (max-width: 768px) {
    .fieldsHeader {
        padding-top: 35px;
        padding-bottom: 15px;
    }

    .loan-selectors {
        display: block;
    }
}

.pretTitle {
    font-size: 32px;
    font-family: Montserrat, sans-serif;
    color: #1e2053;
    font-weight: 600;
    text-align: center;
}

.step1subtitle {
    margin: 20px 0 30px;
    font-size: 20px;
    color: #000000;
    display: inline-block;
}

.step1subtitle strong {
    /*color: #7048e8;*/
}

button.floatingBackButton {
    top: 13px;
    background-color: #e9ecef;
}

button.floatingBackButton i.backIcon {
    color: #adb5bd;
    font-size: 40px;
    font-weight: bold;
    margin-left: -3px;
}

.step1 .checkboxes {
    margin: 25px 0;
    text-align: left;
}

.step1 .checkboxes > div {
    text-align: center;
}

.title {
    margin-bottom: 10px;
    color: #666666;
    font-weight: 600;
}

.titlePrets {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 900;
    max-width: 450px;
    font-size: 35px;
    text-align: center;
    color: rgb(45, 50, 45);
    display: inline-block;
}

@media only screen and (max-width: 576px) {
    .step1 .checkboxes > div {
        text-align: left;
    }
}

.ChooseFlowButtonGroup {
    margin-bottom: 25px;
}

.ChooseFlowButtonGroup .formButton {
    padding: 5px;
    opacity: .7;
}

.ChooseFlowButtonGroup .formButton.selected {
    opacity: 1;
    /*padding: 9px;*/
    /*margin-top: -2px;*/
}


.dialog-enter {
    opacity: 0.01;
    /* transform: scale(1.1); */
    margin-top: 100px;
}

.dialog-enter-active {
    opacity: 1;
    /* transform: scale(1); */
    margin-top: 0;
    transition: all 300ms ease-out;
}

.dialog-exit {
    opacity: 1;
    margin-top: 0;
    /* transform: scale(1); */
}

.dialog-exit-active {
    opacity: 0.01;
    /* transform: scale(1.1); */
    margin-top: -100px;
    transition: all 400ms ease-out;
}

.loan-number {
    color: #fff !important;
    width: 50px;
    line-height: 30px;
    text-align: center;
    height: 30px;
    margin: 0 10px 0 0;
    display: inline-block;
    border-radius: 8px;
    cursor: pointer;
    border: 3px solid rgb(63, 165, 238);
    font-weight: bold;
    box-shadow: 0 2px 10px rgba(63, 165, 238, 0.2);
    transition: border 0.15s ease-in-out;
}

.loan-number:hover {
    border: 3px solid #fff;
}

.triangle:hover {
    font-weight: 500;
    padding: 15px 30px;
    color: #5372e4;
    border:2px solid #5372e4;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.triangle {
    /* position: absolute;
    left: 20px;
    top: 20px; */
    text-align: center;
    width: 6vw;
    padding: 15px 30px;
    border: 2px solid #edf1f8;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 6px 16px 0 rgb(0 0 0 / 16%);
    font-family: Montserrat, sans-serif;
    color: #819ab4;
    font-size: 20px;
    font-weight: 600;
}
    .pretsFrame {
        padding: 25px 40px;
        margin-top: 8vh;
        border-radius: 25px;

        /*background-image: linear-gradient( 157deg , #5372e4, rgb(63, 165, 238, 0.9));*/

        box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
    }

@media only screen and (max-width: 400px) {
    .pretsFrame {
        padding: 25px 37px;
        margin-top: 8vh;
        border-radius: 25px;
        background-color: white;
        box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
    }
  }



.loan-number.active {
    border: 3px solid #fff;
}

/*.additionalLoan:before {*/
/*    content: "";*/
/*    position: absolute;*/
/*    margin-left: -25px;*/
/*    margin-top: 50px;*/
/*    color: #5392e8;*/
/*    font-weight: bold;*/
/*}*/
/*.additionalLoan0:before {*/
/*    content: "#1";*/
/*}*/
/*.additionalLoan1:before {*/
/*    content: "#2";*/
/*}*/
/*.additionalLoan2:before {*/
/*    content: "#3";*/
/*}*/
/*@media screen and (max-width: 768px) {*/
/*    .additionalLoan:before {*/
/*        display: none;*/
/*    }*/
/*}*/


.MuiInput-underline:before {
    border-bottom: none !important;
}

/*.MuiInputBase-input.MuiSelect-select {*/
/*    border: 1px solid rgb(225,225,225);*/
/*}*/
.MuiTextField-root .MuiInputBase-root.MuiInput-root, .MuiFormControl-root .MuiInputBase-root.MuiInput-root {
    border: 1px solid rgb(225, 225, 225);
}

.smallerText {
    font-size: .8rem;
    line-height: 1.5rem;
    color: rgb(102, 102, 102);
}

.greenPrice {
    color: rgb(50, 206, 155);
    font-size: 1.4rem;
}

.tableRow {
    margin: 10px 0;
}

.mainPrice {
    font-weight: 500;
    font-size: 2rem;
    color: #000;
}

.optionsButton {
    border-radius: 15px !important;
    box-shadow: none !important;
    border: none !important;
    padding: 10px 17px !important;
    text-transform: none !important;
}
.optionsButton:not(.selected) {
    background: none !important;
}
.MuiTimelineItem-missingOppositeContent:before {
    content: none !important;
}

.selected {
    background: #F6B60D;
    border-radius: 35px !important;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 24px !important;
}

.radioText {
    font-size: .95rem !important;
    line-height: 2.2rem !important;
    font-weight: 500;
    color: rgb(45, 50, 45) !important;
    padding: 20px 0;
}

.radioSelectionText {
    font-size: .95rem !important;
    color: rgb(45, 50, 45);
}

.MuiTimelineItem-missingOppositeContent:before {
    -webkit-flex: 0 1 !important;
            flex: 0 1 !important;
    margin-left: 28%;
}
.MuiTimelineDot-defaultPrimary
{
    background-color: rgb(50, 206, 155) !important;
}
.MuiTimelineDot-root {
    border: 1px solid rgb(50, 206, 155) !important;
}
.MuiSlider-root {
    color: #F6B60D !important;
}
.MuiTimelineContent-root {
    padding: 10px 16px !important;
}

.dialog-sheet {
    z-index:99999 !important;
    background: #fff;
    border: 1px solid #666;
    border-radius: 20px;
}

@media only screen and (min-width: 500px) {
    .style_equivalenceHeading__21hsR {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
}

@media only screen and (max-width: 500px) {
    div.style_equivalenceName__1ls9G {
        padding: 6px 0;
    }

    i.style_hint__1cU-7:before {
        top: 2px;
    }
}

.style_equivalenceName__1ls9G {
    /*font-size: 14px;*/

    /*line-height: 22px;*/
    padding: 4px 0;
}

/*.animatedRow {*/
/*    opacity: 0;*/
/*    width: 0;*/
/*}*/

/*.animatedRow.show  {*/
/*    !*height: 2em;*!*/
/*    width: 500px;*/
/*    opacity: 1;*/
/*    transition: all 0.4s ease-in;*/
/*}*/

.style_equivalencePadding__3k0I0 .style_check__1CVhf,
.style_equivalencePadding__3k0I0 .style_uncheck__o-zFn {
    float: none;
    /*padding: 10px;*/
    /*border-bottom: 1px solid rgba(150, 150, 150, 0.4);*/
    /*-webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, .2);*/
    /*box-shadow: 0 2px 10px rgba(0, 0, 0, .2);*/
}

.style_logoGuarantees__2CdWV{
    /*width: 600px;*/
    text-align: center;
}

.style_logo__gjj_N {

    /*width: 240px;*/
    /*opacity: 0;*/
    height: 145px;
    opacity: 0;
    width: auto;

    /*width: 150px;*/
    margin: 15px 30px;

    margin-top: 150px;

    display: inline-block;
    vertical-align: middle;
    transition: opacity .6s linear, height .5s cubic-bezier(.88,.18,.83,.67), margin .5s cubic-bezier(.88,.18,.83,.67);
}

.style_logo__gjj_N.style_insurer__lsa1L {
    transition-delay: .6s;
}

.style_loader__2Wuvq {
    margin: 30px auto;
    transition: opacity .6s linear, height .4s cubic-bezier(.88,.18,.83,.67);
    opacity: 0;
}
.style_loader__2Wuvq.style_show__3CMcz {
    opacity: 1;
}
.style_loader__2Wuvq.style_minimize__3UoZf {
    opacity: 0;
    height: 0!important;
}
.style_logo__gjj_N.style_show__3CMcz.style_logo-afi-esca__wFfNO,
.style_logo__gjj_N.style_logo-afi-esca__wFfNO {
    height: 80px;
}
.style_logo__gjj_N.style_logo-afi-esca__wFfNO.style_minimize__3UoZf {
    height: 55px;
}
.style_logo__gjj_N.style_show__3CMcz {
    height: 145px;
    opacity: 1;
    /*margin-top: -10px;*/
    /*padding-bottom: 30px;*/
}

@media screen and (max-width: 768px) {
    .style_logo__gjj_N {
        margin-top: 60px;
    }
    .style_logo__gjj_N.style_wedou__2cjDW {
        display: none;
    }
    .style_logo__gjj_N.style_minimize__3UoZf.style_wedou__2cjDW {
        opacity: 0;
        /*height: 0!important;*/
        margin: 0;
        margin-left: -55px;
    }
    .style_logo__gjj_N.style_insurer__lsa1L {
        transition-delay: 0s;
    }
    .style_logo__gjj_N.style_minimize__3UoZf {
        height: 60px!important;
        margin: 15px 15px;
    }
}

.style_logo__gjj_N.style_minimize__3UoZf {
    height: 90px;
    margin-top: 15px;
    transition-delay: 0s!important;
}

.style_logo__gjj_N.style_axa__39i_i {
    width: 85px;
}

.style_check__1CVhf {
    color: green;
    padding: 5px;
    float: right;
}

.style_insurer-card__2O7CO {
    display: -webkit-flex;
    display: flex;
    width: 32%;
    max-width: 430px;
    margin-right: 10px;
    margin-left: 10px;
    padding: 25px 35px 35px;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    align-items: flex-start;
    border: 1px none #ebecf0;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 4px 60px 0 rgb(116 134 235 / 7%), 0 4px 26px 0 rgb(0 0 0 / 5%);
}

.style_icon__3-3dA {
    padding-right:8px;
    padding-left: 8px;
    display: inline-block;
}

.style_check_voyage__15GIM {
    color: #15de95;
    border-radius: 15px;
    background-color: #eafcea;
    width: 20px;
}

.style_uncheck__o-zFn {
    color: red;
    padding: 5px;
    width: 14px;
    float: right;
}

.style_green__vbJgV {
    color: green;
}
.style_bold__Hq4xe {
    font-weight: bold;
}
.style_red__220Zi {
    color: red;
}

.style_hint__1cU-7:before {
    top: 0;
    margin-left: -16px;
    position: absolute;
}

.style_footer__35__1 {
    margin-top: 1rem;
}

.style_footerText__5kQK2 {
    text-align: center;
    padding-top: 1rem;
}

.style_tableHeading__1ivWf {
    text-align: center;
    /*background: #f8f8f8;*/
    border: 1px solid #f1f1f1;
    background: #f1f1f1;
    border-bottom: none;
    font-size: 17px;
    color: #585858;
    font-weight: 450;
}

.style_guarantie__3c_ia {
    text-align: left;
    font-family: Montserrat, sans-serif;
    color: #4c4c4c;
    font-size: 16px;
    font-weight: 300;
}

.style_tableHeading__1ivWf.style_premium__1aXxT {
    margin-top: 15px;
}

p.label {
    font-size: 18px;
}
.sectionHeading p {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    -webkit-margin-before: 0.3rem;
            margin-block-start: 0.3rem;
}

.buttonSection {
    padding-top: 25px;
    padding-left: 22px;
}

.loanSection span {
    margin-left: 20px;
}

.loanSection .loanAmount {
    font-weight: bold;
    font-size: 18px;
}

.titleInstallments{
    text-align: center;
    color: black;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    font-family: 'Montserrat' !important;
}

p.or {
    position: relative;
    height: 50px;
    line-height: 50px;
    text-align: center;
}
p.or::before,
p.or::after {
    position: absolute;
    width: 43%;
    height: 1px;
    top: 24px;
    background-color: #aaa;
    content: '';
}
p.or::before {
    left: 0;
}
p.or::after {
    right: 0;
}


.arrow {
    position: relative;
    margin: 0 auto;
    width: 100px;
}

.arrow .curve {
    border: 2px solid #BE5F4B;
    border-color: transparent transparent transparent #BE5F4B;
    height: 360px;
    width: 1200px;
    border-radius: 230px 0 0 150px;
}

.arrow .point {
    position: absolute;
    left: 40px;
    top: 315px;
}

.arrow .point:before, .arrow .point:after {
    border: 1px solid #BE5F4B;
    height: 25px;
    content: "";
    position: absolute;
}

.arrow .point:before {
    top: -11px;
    left: -11px;
    transform:rotate(-74deg);
    -webkit-transform:rotate(-74deg);
    -moz-transform:rotate(-74deg);
    -ms-transform: rotate(-74deg);
}

.arrow .point:after {
    top: -20px;
    left: 5px;
    transform:rotate(12deg);
    -webkit-transform: rotate(12deg);
    -moz-transform:rotate(12deg);
    -ms-transform: rotate(12deg);
}
.buttonGroup .formButton.lightBlueButton {
    background: #2daddd;
    border-color: #2daddd;
    color: white;
}

.mobileStep span.MuiFormControlLabel-label {
    font-size: 15px;
}

.economieWrapper {
    color: #000000;
    font-weight: 500;
}

.mobileStep .MuiIconButton-root {
    padding: 5px 10px;
}
.mobileStep button.MuiButtonBase-root.MuiButton-root.formButton.MuiButton-text {
    margin-bottom: 5px;
}

.voila {
    font-size: 17px;
    padding-top: 15px;
}
.insurancePopup {
    width: 800px;
}

ard {
	padding: 20px;
	border-radius: 40px;
	margin: 10px 10px;
	overflow: hidden;
    background-color: white;
}

.insurer-card-logo {
	height: 45px;
    text-align: center;
}

.insurer-card-logo-afiesca {
	height: 50px;
    text-align: center;
}

.insurer-card-logo-afiesca img {
	height: 2.3vw;
}

@media only screen and (max-width: 550px) {
    .insurer-card-logo-afiesca img {
        height: 50%;
    }
  }

.insurer-card-logo img {
	height: 100%;
}

@media only screen and (max-width: 550px) {
    .insurer-card-logo img {
        height: 60%;
    }
  }

.insurer-card-separator {
	border-color: #fff;
	margin: 20px 0 30px 0;
}

.insurer-card-row {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	margin: 10px 0;
	-webkit-align-items: baseline;
	        align-items: baseline;
	text-align: left;
	text-align: initial;
}

.insurer-card-row:last-child {
	margin-bottom: 0;
}

.insurer-card-first-row {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.insurer-card-number {
	font-weight: bold;
    font-size: 1.7vw;
    font-family: Poppins-Medium;
    letter-spacing: -2px;
}

.insurer-card-number-mobile {
	font-weight: bold;
    font-size: 1.2rem;
}

.insurerTitle {
    margin-bottom: 5px;
    font-size: 1.2vw;
    color: #2e305f;
    font-family: Poppins-Medium, sans-serif;
    font-weight: 500;
}

.insurerTitleMobile {
    margin-bottom: 5px;
    font-size: 14px;
    font-family: Montserrat, sans-serif;
    color: #18143b;
    font-weight: 500;
}


.guarantees-cards-wrapper .insurer-card-separator {
	margin-bottom: 0;
}

.extra-guarantees-submenu {
	padding: 20px;
    font-weight: 600;
	margin: 0;
	text-align: center;
}

.guarantees-cards-wrapper .insurer-card-row{
	margin: 10px 0;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
}

.extra-guarantees-tooltip {
	min-width: 44px;
}
.dataTable {
    padding: 15px;
    text-align: left;
}

.bravoMessage {
    font-size: 18px;
    line-height: 1.5;
    margin-top: 2rem;
}
.bravoEmailForm {
    margin-top: 3rem;
}
.form {
    width: 100%;
}
.suggestion-item,
.suggestion-item--active {
    cursor: pointer;
    padding: 8px 15px;
}
.suggestion-item--active {
    background: rgba(50,50,50,0.2);
}
.placesInputWrapper {
    width: 100%;
}
.autocomplete-dropdown-container {
    box-shadow: 0 2px 10px rgba(0,0,0,.2);
    position: absolute;
    background: #ffffff;
    min-width: 300px;
    z-index: 100;
}
.MuiFormLabel-root.MuiInputLabel-formControl {
    padding: 0 4px;
}

.retour_button {
    border: 1px solid black;
}
.smoker {
    margin-left: 15px;
}
.professions .MuiTextField-root,
.riskyProfessions .MuiTextField-root,
.riskyProfessions .MuiFormControl-root {
    max-width: 450px;
}

.professions .MuiSelect-select.MuiSelect-selectMenu,
.riskyProfessions .MuiSelect-select.MuiSelect-selectMenu {
    min-width: 300px;
    box-sizing: border-box;
}

.MuiInputLabel-formControl {
    padding: 0 !important;
    color: #585858 !important;
}
@media screen and (max-width: 400px) {
    .professions .MuiSelect-select.MuiSelect-selectMenu,
    .riskyProfessions .MuiSelect-select.MuiSelect-selectMenu {
        min-width: 235px;
    }
}

.riskyProfessions .MuiIconButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.54);
}
/*.riskyProfessions:first-child {*/
/*    color: #3451b9;*/
/*}*/
.profession-question {
    margin-right: 30px;
}
.profession-question-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    margin-bottom: 20px;
}

.titleModalQuest {
    font-size: 22px;
    text-align: left;
    font-weight: 600;
    color:#2e305f;
} 

@media only screen and (max-width: 400px) {
    .profession-selector {
        max-width: 600px;
        -webkit-flex: 1 1;
                flex: 1 1;
        padding: 0 15px;
    }
  }

  @media only screen and (min-width: 400px) {
    .profession-selector {
        min-width: 300px;
        max-width: 600px;
        -webkit-flex: 1 1;
                flex: 1 1;
        padding: 0 15px;
    }
  }
.focusText {
    font-size: 24px;
    text-align: center;
}

.focusText.underlined {
    text-align: center;
    padding: 35px 0;
    margin: 10px 30px;
    border-bottom: 1px solid #999;
}

.healthQuestion {
    font-size: 17px;
    line-height: 1.5;
    color: #2e305f !important;
}
.radioButton span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 > span{
    color: #2e305f !important;
}
.clickedButton {
    border: 2px solid #75ACBA !important;
    border-radius: 10px;
    color: #75ACBA;
    padding: 2px 13px;
    font-weight: bold !important;
    text-align: center;
}

.grayFrame {
    background-color: #fafbff;
    border-radius: 20px;
    padding: 15px;
}

.questCol {
    margin-bottom: 3vh;
}

@media only screen and (min-width: 600px) {
    .questCol .radioButton {
        margin-right: 1.5vw;
    }
}


.radioButton {
    border: 1px solid #d1d8e0;
    border-radius: 10px;
    padding: 2px 13px;
    font-weight: bold !important;
    text-align: center;
}

.radioButton:hover {
    border: 2px solid #4c66c8;
    border-radius: 10px;
    background-color: #f8fafc;
    font-weight: bold !important;
    text-align: center;
}

.styleCheckBox {
    padding: 20px;
}

.smallFont {
    font-size: 12px;
    line-height: 1.5;
    text-align: justify;
}

.deffered {
    margin-left: 30px;
    font-weight: bold;
}
.resumeRow:hover {
    padding: 10px 0;
    cursor: pointer;
    background: #f1f1f1;
}
.MuiTableCell-root.resumeCell.resumeCell {
    border: none;
    padding: 10px 25px;
    word-break: break-word;
}

.MuiTableCell-root.resumeCell.resumeCell.rightCell {
    border: none;
    padding: 10px 25px;
    font-weight: 600;
    font-size: 16px;
    word-break: break-word;
}
.resumeCell .fa-pencil {
    padding-left: 10px;
}

.resumeLoan {
    display: -webkit-flex;
    display: flex;
    margin-top: 15px;
}

.resumeFrame {
    padding: 15px;
    margin-top: 4vh;
    border-radius: 25px !important;
    background-color: white;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%) !important;
}

@media screen and (max-width: 400px) {
    .titles {
        text-align: center;
        font-size: 25px;
        font-weight: 600;
        color: #18143b;
    }
}

.titles {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #18143b;
}

#selector {
    overflow: hidden;  /* Hide the element content, while height = 0 */
    height: 0;
    opacity: 0;
    transition: height 0ms 400ms, opacity 400ms 0ms;
}
#selector.visible {
    height: auto; opacity: 1;
    transition: height 1ms 1ms, opacity 600ms 1ms;
}

#selector.hide {
   -webkit-animation: hide 1s linear;
           animation: hide 1s linear;
   -webkit-animation-fill-mode: forwards;
           animation-fill-mode: forwards;
}

.toggleTable:hover {
    background-color: #E8E8E8;
    cursor: pointer;
}

.resumeEdit {
    margin: auto;
    cursor: pointer;
    text-align: center;
    width: 120px;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 5px;
    padding: 10px;
    background: white;
    display: inline-block;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}
.resumeEdit:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
    background: ghostwhite;
    color: white;
}
.updateForm {
    min-width: 700px;
}

h1 {
    font-size: 40px;
    text-align: center;
    white-space: nowrap;
}

.style_row__2wyt9 {
    margin-top: 30px;
    margin-bottom: 0;
}

.style_result__1amjL {
    font-size: 20px;
    line-height: 1.5;
}

.style_stepSubtitle__L-KCn p {
    font-size: 1.5em;
    font-weight: 300;
}

.style_frameBorder__1Oklo {
    border-radius: 15px;
    padding: 20px;
    color: #2e305f;
    margin-top: 8vh;
    border-radius: 25px;
    background-color: white;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
}

.style_headerTitle__2UYuz {
    font-size: 25px;
}

.style_moneyPar__1JRKS {
    text-align: center;
    font-size: 1vw;
    font-weight: 450;
    display: block;
    min-height: 44px !important;
}

.style_offreButton__3VIPw {
    height: 6vh;
    cursor: pointer;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 1.05em;
    text-align: center;
    border-radius: 15px;
    border: 2px solid #5d7ae4;
    background-color: white;
    color: #5d7ae4;
}

.style_offreButton__3VIPw:hover {
    height: 6vh;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 15px;
    font-size: 1.05em;
    text-align: center;
    border: 2px solid white;
    background-color: #5d7ae4;
    color: white;
}
.style_offreButton__3VIPw * {
    vertical-align: middle;
}

.style_moneyParMobile__Zj0n5 {
    text-align: center;
    font-size: 20px;
    font-weight: 450;
}

.style_registerSection__3RKTW p {
    font-size: 18px;
    text-align: center;
}

.style_acceptTerms__1HJsa {
    line-height: 1.5;
    margin-top: .5em;
}

.style_odometerWrapper__2dSPW {
    font-size: 1.5vw;
    margin: 10px;
}

@media only screen and (max-width: 1300px) {
    .style_odometerWrapper__2dSPW {
        font-size: 1.7rem;
        margin: 10px;
    }
}

@media only screen and (max-width: 400px) {
    .style_odometerWrapper__2dSPW {
        font-size: 22px;
        margin: 10px;
    }
}

@media only screen and (min-width: 500px) {
    .style_totalSavings__1g-oF h4 {
        font-size: 25px;
        -webkit-margin-before: 0;
                margin-block-start: 0;
        -webkit-margin-after: 0;
                margin-block-end: 0;
    }

    /*.offerRow {*/
    /*    margin-top: 6rem;*/
    /*}*/
}
.style_offerRow__1NwDu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}

.style_imgLogo__jOJF_ {
    height: 100px;
    margin-right: 20px;
}

.installments-table-wrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	-webkit-align-items: flex-start;
	        align-items: flex-start;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	width: 100%;
    font-family: 'Montserrat' !important;
}

.installments-table-wrapper .MuiPaper-root {
	min-width: 240px;
	-webkit-flex: 1 1;
	        flex: 1 1;
}

.installments-table-wrapper th {
	font-weight: bold;
}

.installments-table-wrapper .MuiTableCell-root {
	padding: 8px 16px;
}

.installments-modal-text-wrapper {
	font-family: 'Montserrat' !important;
}

.installments-modal-text-wrapper .radioGroup.MuiFormGroup-root {
	margin-left: 0px;
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
}
.style_container__38t3i {
    font-family: Poppins;
    letter-spacing: 0.40px;
    font-weight: 900;
    -webkit-justify-content: center;
            justify-content: center;
    color: #88BAC7;
    display:-webkit-flex;
    display:flex;
}
.style_big__2l5D0 {
    font-size: 69px;
    position: relative;
    bottom: 8px;
}
.style_medium__3ks2G {
    font-weight: 600;
    font-size: 40px;
}
.style_small__3kW7K {
    position: relative;
    bottom: 10px;
    right: 6px;
    font-size: 20px;
}

.phoneNumberPage {
    line-height: 1.5;
    font-size:0.9rem;
}
.documentsList li {
    margin-bottom: 5px
}
.c-sign__stamp {display: none !important;}
.style_signing__2_D_w {
    margin-top: 50px;
    width: 1400px;
    max-width: 100%;
    height: 1000px;
    border: none;
}
.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-roller div {
    -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    -webkit-transform-origin: 40px 40px;
            transform-origin: 40px 40px;
}
.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #858484;
    margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
    -webkit-animation-delay: -0.036s;
            animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}
.lds-roller div:nth-child(2) {
    -webkit-animation-delay: -0.072s;
            animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}
.lds-roller div:nth-child(3) {
    -webkit-animation-delay: -0.108s;
            animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}
.lds-roller div:nth-child(4) {
    -webkit-animation-delay: -0.144s;
            animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}
.lds-roller div:nth-child(5) {
    -webkit-animation-delay: -0.18s;
            animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}
.lds-roller div:nth-child(6) {
    -webkit-animation-delay: -0.216s;
            animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}
.lds-roller div:nth-child(7) {
    -webkit-animation-delay: -0.252s;
            animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}
.lds-roller div:nth-child(8) {
    -webkit-animation-delay: -0.288s;
            animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}
@-webkit-keyframes lds-roller {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes lds-roller {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.sci-signing {
    margin-top: 50px;
    width: 1400px;
    max-width: 100%;
    height: 1000px;
    border: none;
}

#physicalOne .PrivateValueLabel-label-5:after,
#physicalTwo .PrivateValueLabel-label-5:after,
#physicalThree .PrivateValueLabel-label-5:after
{
    content: ' min'
}
#foodOne .PrivateValueLabel-label-5:after {
    content: ' / j'
}
#foodTwo .PrivateValueLabel-label-5:after {
    content: ' %'
}
#foodThree .PrivateValueLabel-label-5:after {
    content: ' kg.'
}
#doctorsOne .PrivateValueLabel-label-5:after,
#doctorsTwo .PrivateValueLabel-label-5:after,
#doctorsThree .PrivateValueLabel-label-5:after
{
    content: ' mois'
}
.PrivateValueLabel-offset-3 {
    font-size: 0.6rem !important;
}
.PrivateValueLabel-circle-4 {
    width: 40px !important;
}

.welcome_franceConnectLogo__3NdxQ {
    height: 125px;
}
.welcome_continueButton__2dFAp {
    background-color: #0556CC!important;
    font-size: 20px !important;
}

.personalPaper .question-icon i{
    margin: 0 10px;
    font-size: 16px;
}

.personalLink {
    color: black;
    font-weight: 500;
    border-bottom: 1px solid #ffffff;
    padding: 0 5px;
    cursor: pointer;
    display: inline-block;
}

.personalFrame {
    position: static;
    right: 20px;
    bottom: 0;
    padding: 20px;
    margin-top: 40px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: -1px 6px 40px 0 rgb(118 126 173 / 34%);
    opacity: 1;
}

.personalLink:hover {
    cursor: pointer;
    text-decoration: underline;
}
.header{
    text-align: center;
}

.renunciaton {
    cursor: pointer;
    padding: 5px;
    display: inline-block;
    margin-top: 50px;
}

.renunciaton:hover {
    border-radius: 8px;
    background-color: white;
    color: black;    
}

.contrat {
    border: 2px solid #00d57c;
    border-radius: 20px;
    font-weight: 600;
    padding: 5px;
    color: #00d57c;
}

.personalHomeFrame {
    border: 1px solid #9badee;
    color: white;
    text-align: center;
    font-weight: 500;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
}

.dateSignature {
    border-radius: 20px;
    padding: 5px;
    color: rgb(55, 51, 85); 
    box-shadow: 0px 0px 9px 0 rgb(118 126 173 / 34%);
}

.resignationQuestions .radioButton {
    width: 100%;
}

.renunciation {
    position: fixed;
    bottom: 0;
    left: 275px;
    cursor: pointer;
}
.affiliate_buttons_circle___r9CY {
    position: absolute;
    width: 135px;
    left: -25px;
    top: -15px;
}

.affiliate_buttons_listEntry__1Q_HZ {
    margin-left: 10px;
}

.affiliate_buttons_listEntry__1Q_HZ::before {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAlCAYAAAAuqZsAAAAABHNCSVQICAgIfAhkiAAABDFJREFUWEfNWFuME2UUPqctQrZIZEEMDygxsBtYdqZmWX0RUphykUQTAoHwgA9qIDEBEpbOKgGpQBCmJRgSH4xogvFNDCQYUJnulgDRjch2isuG2xsPPJHgsgVDO4czXYZ227nt6LbMSzvzf+f7v57/3KYIz8nVcTnRNHVCeCcAfcySVHwedEW15IIQwUlAmGPoIaS9DRcmZQ8tQQycZj3hkiiAB0OPHs9uqDDp71QnFvQMIDaVT47iqiinGiZMGkjNZ1EXAXCqKYoIbqQj8VbjviHCFl89POuFot7Hu88cFeOEK9XIjl8bImy5lpyhE1wyA73sLTqVjsirzfu6euytm0enTM7/e4E3FUZ7ivIFpNaM2H2n/sIoEYjlwmd44xU1JQppuyrIRyqf181jkqZwpmFXtSgO+P5m8bXOH3Fdse7CYpryPufZcatiTgEQ0+3xXPXauHsslk29DUgXLEURHOLy8InV2rgKK5UFvahV1qpnWQh0J98cavl91vaHdRUWHfhqcvBxvg8R5lv244qaVVdhkpY8w8fxjuURAv2UFuW1TgPEqKOUssp6Bm/mRpq9X8jv+mthIu9n+rDLwJEmTUPFELRk2uS7rsKi/V/MDmLwO0RcUo4BOJsW46vGKiyWVT7gpvytrR3CJlWIf+PGi4uuHHh5YjB0mQP01Row0X41Iu92IzHXl+ZSiwNE5x3wF1UxvsgLH8a05PcM3GgHJtRXp4XuU25kUu7I60iFPxnXbIfVAzSnp12+7cZlrGMsm7zPDXWKA3gYAnqn2t49aIcxeuCL+Ud/MN08Wx6iT9n7B72IKgnjQL3LreIVRwOCW0PhiR19c7f+Y4Vjr//Cz2t74FMwtx2NC2nEq6gRj2nKGv444WbEmaqmhR3LObD5a/mK5ZSjPKRvsQ0FogIEscOq7TjtWSoXXHOO8ZcPXcUR7ONf/pmJW6opHwUAHTOMiPbwnLXXjbt6vSSMh7ewTtTP3pjrRqADvNcjxk/HriajoEOvE97PEZp8zwrssgElQgXsdxNmvMUA0QZuNZzN5Xndyg5D9Ma5NjnrxmlpW/mQX6W6+FUq5YfIwibFNSvul6tmuuC29Bt3gGV+CUt2nMXD04KC3eTghbtGWPR6anrwIQ3yUU33QlCLIZ3jsLNHlK/4sx+xspzHuLat5Np21g+x8XqfFuQ9fmwrbWwHRRb3JYvbNrYNaLAg5IUMJgpjs6tFO06wXHx5Fsd2r5sUg4FI74Iunlj/++UoTMoebgEsauy5SR62SnAWfu4B5wniOvNLOWUTEn7txMaF9FpRHBb/jyM093EVZgA53k6w17inWl/cdt7ltvOzJ1d4BHkSZvzb91KoqZfr25vVvOytH7h/2s5zHnXUwDwJM62qJol7/Pwgx1XS7+ZOdmMSZhBFexMhmAGTMm2JB+MhyOR8AoTAaTajwV+eAAAAAElFTkSuQmCC) no-repeat 0 0;
    background-size: 100%;
    content: "";
    width: 16px;
    height: 16px;
    position: relative;
    display: inline-block;
    left: -10px;
}

.affiliate_buttons_affiliateButtons__1Frcq {
    margin-bottom: 0 !important;
}

.affiliate_buttons_popupRewardText__1wKw2 {
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    padding: 20px 0;
    margin: 35px auto;
    border-top: solid 1px rgba(80, 80, 80, 0.3);
    border-bottom: solid 1px rgba(80, 80, 80, 0.3);
}

.affiliate_buttons_popupImageStyle__1E80J {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 350px;
    top: -200px;
    margin-left: -20px;
}
.steps .fileRow {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 20px;
    padding: 15px 40px;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-align-items: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(129, 154, 180, 0.4);
    border-radius: 10px;
}

.reactIcons {
    margin-right: 15px;
}

.stepsInfo {
    padding: 26px;
    border-radius: 20px;
    background-color: #fafbff;
}

.steps {
    width: 80%;
    margin: 40px auto;
    padding: 35px 40px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 6px 30px 0 rgb(59 61 100 / 16%);
}

.steps .fileRow,
.steps .ibanRow {
    margin-top: 30px;
}

.steps .ibanRow .fa-question-circle {
    font-size: 15px;
}

.personal_nav {
    text-align: center;
    margin-top: 20px;
}

.personal_nav_span {
    color: #2e305f;
    font-size: 20px;
}

.active_steps {
    color: #5372e4;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
    /*border-bottom: 3px solid #5372e4;*/
    text-decoration: underline;
    padding: 5px;
}

.transferredButton {
    padding: 10px 20px;
    display: inline-block;
    margin-top: 10px;
    cursor: pointer;
    border-style: solid;
    border-width: 2px;
    border-color: #00d57c;
    border-radius: 10px;
    background-color: #00d57c;
    font-family: Montserrat, sans-serif;
    color: white;
    font-size: 16px;
    width: 100%;
    font-weight: 600;
    text-align: center;
}

.tickIcon {
    background-color: white;
    color: #00d57c;
    border-radius: 20px;
}

.personal_nav_span:hover {
    color: #5372e4;
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
}

.stepsButton {
    padding: 10px 20px;
    display: inline-block;
    margin-top: 10px;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    background-color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    width: 100%;
    font-weight: 600;
    text-align: center;
}

.stepsButton:hover {
    color: white;
    box-shadow: 0 6px 30px 0 rgb(59 61 100 / 16%);
    cursor: pointer;
}

.steps .fileRow p.name {
    color:#2e305f;
    font-size: 17px;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    padding: 20px 15px;
    -webkit-align-items: center;
            align-items: center;
}

.stepsImg {
    display: inline-block;
    margin-right: 15px;
}

.steps .fileRow p.missing {
    color: red;
    font-size: 18px;
    font-style: italic;
}

.steps .fileRow p.transferred {
    color: green;
    font-size: 18px;
    font-style: italic;
}

.steps .fileRow p.name,
.steps .fileRow p.missing,
.steps .fileRow p.transferred {
    margin: 0;
    font-size: 16px;
}

.steps .infoText {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: Montserrat, sans-serif;
    color: #2e305f;
    font-size: 16px;
    font-weight: 500;
}

.documentsFrame {
    position: static;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    padding: 25px;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: -1px 6px 40px 0 rgb(118 126 173 / 34%);
    opacity: 1;
    -webkit-transform: translateZ(0) scaleX(1) rotateX(
0deg) rotateY(
0deg) rotate(
0deg) skew(
0deg,
0deg);
    transform: translateZ(0) scaleX(1) rotateX(
0deg) rotateY(
0deg) rotate(
0deg) skew(
0deg,
0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.documentsFrame {
    position: static;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    padding: 25px;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: -1px 6px 40px 0 rgb(118 126 173 / 34%);
    opacity: 1;
    -webkit-transform: translateZ(0) scaleX(1) rotateX(
0deg) rotateY(
0deg) rotate(
0deg) skew(
0deg,
0deg);
    transform: translateZ(0) scaleX(1) rotateX(
0deg) rotateY(
0deg) rotate(
0deg) skew(
0deg,
0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.documentsFrame {
    position: static;
    bottom: 0;
    display: -webkit-flex;
    display: flex;
    padding: 25px;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: -1px 6px 40px 0 rgb(118 126 173 / 34%);
    opacity: 1;
    -webkit-transform: translateZ(0) scaleX(1) rotateX(
0deg) rotateY(
0deg) rotate(
0deg) skew(
0deg,
0deg);
    transform: translateZ(0) scaleX(1) rotateX(
0deg) rotateY(
0deg) rotate(
0deg) skew(
0deg,
0deg);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}
.animateEntry {
    opacity: 0;
    transition: .5s opacity linear;
}
.animateEntry.done {
    opacity: 1;
}

.modal-text-wrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
    font-size: 0.9em;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	margin: 10px 0;
    font-family: 'Montserrat' !important;
}

.guarantiesFrame {
    padding: 25px 40px;
    margin-top: 8vh;
    border-radius: 25px;
    background-color: white;
    box-shadow: 0 1px 5px rgb(0 0 0 / 20%);
}

.comparisonFrame {
    display: -webkit-flex;
    display: flex;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    padding: 35px;
    -webkit-flex-direction: column;
    flex-direction: column;
    border-radius: 40px;
    background-color: #fff;
    box-shadow: 0 4px 60px 0 rgb(83 114 227 / 8%), 1px 1px 26px 0 rgb(0 0 0 / 5%);
    margin-top: 5vh;
}

.comparisonButton {
    margin: 4vh 0px 1vh 0px;
    width: 100%;
    height: 53px;
    background-color: white;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    font-weight: 600;
}

.selectedInsurerButton {
    margin: 4vh 0px 1vh 0px;
    width: 100%;
    min-width: 200px;
    cursor: pointer;
    height: 53px;
    color: #ffffff;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
}
.comparisonButton:hover {
    width: 100%;
    height: 53px;
    background-color: ghostwhite;
    color: white;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
}

body {
    background-color: white;
}

.container-job{
    /* height: 100vh; */
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin: auto;
    margin-top: 5%;
    margin-left: 150px;
    margin-right: 150px;
    padding-bottom: 5px;
}

.wrapper-col {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    height: 90vh;
}

.file {
    display: none;
}

#job-button {
    font-size: 15px;
    font-family: Monospace;
    margin-top: 20px;
    width: 100%;
    height: 40px; 
    border: solid 2px #3451b9;
    background-color: white;
    color: #3451b9;
    font-family: Montserrat, Roboto, sans-serif;
    font-size: 19px;
    font-weight: 400;
    border-radius: 10px;
 }

 .file-img { 
     margin-left: 10px;
     height: 20px;
 }

 .img-job-woman {
     margin-right: 20px;
 }

 .img-job-man {
    margin-left: 20px;
 }

 #job-button:hover {
     background-color: #3451b9;
     color: white;
 }

 #suivant-job {
     margin-left: 10px;
     margin-right: 10px;
     margin-top:20px;
     width: 240px;
     background-color: #3451b9;
 }

 .error {
     margin-top: 3px;
     text-align: center;
     font-size: 12px;
     color: red;
 }


 .img-div {
     display: -webkit-flex;
     display: flex;
 }

 .field-job {
     width: 240px;
 }

 @media only screen and (max-width: 535px) {
    .label-div-job {
        width: 240px;
    }
  }

 .label-job {
     width: 240px;
     margin-bottom: 10px;
     text-align: center;
     font-size: 22px;
     font-weight: bold;
     color: black;
 }

 .label-job1 {
    width: 270px;
    font-size: 22px;
    padding-left: 15px;
    margin-bottom: 5px;;
    font-weight: bold;
    color: black;
 }

 .job-div {
     width: 240px;
 }

 .text-logged {
    height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    font-weight: bold;
    font-size: 50px;
    color: #ADFF2F;
 }

 .submitted-text {
    margin: 50px;
    text-align: center;
    font-weight: bold;
    font-size: 50px;
    color: #3b7cd1;
 }

 .submitted-text-phone {
    margin: 100px;
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    color: #3b7cd1;
 }

 .redirect {
    width: 200px;
    margin: auto;
 }
.styles_inactivityWrapper__3H5oi{
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #ffffff;
    background: rgba(255, 255, 255, 0.95);
    z-index: 9999;
    position: fixed;
    display: none;
}

.styles_visible__2BlA9 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.styles_restricted__2gL64 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background: rgba(255, 255, 255, 1);
}

.styles_inactivityContent__2qpRc {
    text-align: center;
}

.styles_inactivityLogo__uAYhk {
    width: 100px;
}

.styles_inactivityText__39eBL {
    line-height: 1.3;
    color: #5380ed;
    padding: 0 10px;
}

.styles_inactivityButton__3HdWK {
    background: #4cd964!important;
    width: 150px;
    height: 40px;
    margin: 15px!important;
    color: #ffffff!important;
    font-weight: bold!important;
}
.incomeRow {
    margin-top: 15px;
}

.MuiInput-underline:before {
    border-bottom: none !important;
}

/*.MuiInputBase-input.MuiSelect-select {*/
/*    border: 1px solid rgb(225,225,225);*/
/*}*/
.MuiTextField-root .MuiInputBase-root.MuiInput-root, .MuiFormControl-root .MuiInputBase-root.MuiInput-root {
    border: 1px solid rgb(225, 225, 225);
}

.smallerText {
    font-size: .8rem;
    line-height: 1.5rem;
    color: rgb(102, 102, 102);
}

.greenPrice {
    color: rgb(50, 206, 155);
    font-size: 1.4rem;
}

.tableRow {
    margin: 10px 0;
}

.mainPrice {
    font-weight: 500;
    font-size: 2rem;
    color: #000;
}

.optionsButton {
    border-radius: 15px !important;
    box-shadow: none !important;
    border: none !important;
    padding: 10px 17px !important;
    text-transform: none !important;
}
.optionsButton:not(.selected) {
    background: none !important;
}
.selected {
    background: #F6B60D;
    border-radius: 35px !important;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 10px 24px !important;
}

.radioText {
    font-size: .95rem !important;
    line-height: 2.2rem !important;
    font-weight: 500;
    color: rgb(45, 50, 45) !important;
    padding: 20px 0;
}

.radioSelectionText {
    font-size: .95rem !important;
    color: rgb(45, 50, 45);
}

.incomeCard {
    border: 1px solid #C0CEF7;
    border-radius: 25px;
    background: #fff;
}
.boldGuarantees {
    font-weight: 570;
}
.incomeClarification {
    font-size: 11px;
}
.incomeSlider .MuiSlider-thumb {
    color: #48C4DA !important;
    width: 22px;
    margin-top: -10px;
    height: 22px;
}
.incomeSlider .MuiSlider-root {
    color: #ccc !important;
}
.incomeSlider .MuiSlider-track, .incomeSlider .MuiSlider-rail {
    height: 5px !important;
}

/*#root {*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/

/*body {*/
/*    font-family: -apple-system, BlinkMacSystemFont, sans-serif;*/
/*    font-size: 16px;*/
/*    -webkit-font-smoothing: antialiased;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-content: center;*/
/*    height: 100vh;*/
/*    width: 100vw;*/
/*}*/


/*#payment-message {*/
/*    color: rgb(105, 115, 134);*/
/*    font-size: 16px;*/
/*    line-height: 20px;*/
/*    padding-top: 12px;*/
/*    text-align: center;*/
/*}*/

/*#payment-element {*/
/*    margin-bottom: 24px;*/
/*}*/

/*!* Buttons and links *!*/
/*button {*/
/*    background: #5469d4;*/
/*    font-family: Arial, sans-serif;*/
/*    color: #ffffff;*/
/*    border-radius: 4px;*/
/*    border: 0;*/
/*    padding: 12px 16px;*/
/*    font-size: 16px;*/
/*    font-weight: 600;*/
/*    cursor: pointer;*/
/*    display: block;*/
/*    transition: all 0.2s ease;*/
/*    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);*/
/*    width: 100%;*/
/*}*/

/*button:hover {*/
/*    filter: contrast(115%);*/
/*}*/

/*button:disabled {*/
/*    opacity: 0.5;*/
/*    cursor: default;*/
/*}*/

/*!* spinner/processing state, errors *!*/
/*.spinner,*/
/*.spinner:before,*/
/*.spinner:after {*/
/*    border-radius: 50%;*/
/*}*/

/*.spinner {*/
/*    color: #ffffff;*/
/*    font-size: 22px;*/
/*    text-indent: -99999px;*/
/*    margin: 0px auto;*/
/*    position: relative;*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    box-shadow: inset 0 0 0 2px;*/
/*    -webkit-transform: translateZ(0);*/
/*    -ms-transform: translateZ(0);*/
/*    transform: translateZ(0);*/
/*}*/

/*.spinner:before,*/
/*.spinner:after {*/
/*    position: absolute;*/
/*    content: '';*/
/*}*/

/*.spinner:before {*/
/*    width: 10.4px;*/
/*    height: 20.4px;*/
/*    background: #5469d4;*/
/*    border-radius: 20.4px 0 0 20.4px;*/
/*    top: -0.2px;*/
/*    left: -0.2px;*/
/*    -webkit-transform-origin: 10.4px 10.2px;*/
/*    transform-origin: 10.4px 10.2px;*/
/*    -webkit-animation: loading 2s infinite ease 1.5s;*/
/*    animation: loading 2s infinite ease 1.5s;*/
/*}*/

/*.spinner:after {*/
/*    width: 10.4px;*/
/*    height: 10.2px;*/
/*    background: #5469d4;*/
/*    border-radius: 0 10.2px 10.2px 0;*/
/*    top: -0.1px;*/
/*    left: 10.2px;*/
/*    -webkit-transform-origin: 0px 10.2px;*/
/*    transform-origin: 0px 10.2px;*/
/*    -webkit-animation: loading 2s infinite ease;*/
/*    animation: loading 2s infinite ease;*/
/*}*/

/*@keyframes loading {*/
/*    0% {*/
/*        -webkit-transform: rotate(0deg);*/
/*        transform: rotate(0deg);*/
/*    }*/
/*    100% {*/
/*        -webkit-transform: rotate(360deg);*/
/*        transform: rotate(360deg);*/
/*    }*/
/*}*/

/*@media only screen and (max-width: 600px) {*/
/*    form {*/
/*        width: 80vw;*/
/*        min-width: initial;*/
/*    }*/
/*}*/

.personalPaper .question-icon i{
    margin: 0 10px;
    font-size: 16px;
}

.personalLink {
    color: black;
    font-weight: 500;
    border-bottom: 1px solid #ffffff;
    padding: 0 5px;
    cursor: pointer;
    display: inline-block;
}

.personalFrame {
    position: static;
    right: 20px;
    bottom: 0;
    padding: 20px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: -1px 6px 40px 0 rgb(118 126 173 / 34%);
    opacity: 1;
}

.personalLink:hover {
    cursor: pointer;
    text-decoration: underline;
}
.header{
    text-align: center;
}

.renunciaton {
    cursor: pointer;
    padding: 5px;
    display: inline-block;
    margin-top: 50px;
}

.renunciaton:hover {
    border-radius: 8px;
    background-color: white;
    color: black;    
}

.contrat {
    border: 2px solid #00d57c;
    border-radius: 20px;
    font-weight: 600;
    padding: 5px;
    color: #00d57c;
}

.personalHomeFrame {
    border: 1px solid #9badee;
    color: white;
    text-align: center;
    font-weight: 500;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
}

.dateSignature {
    border-radius: 20px;
    padding: 5px;
    color: rgb(55, 51, 85); 
    box-shadow: 0px 0px 9px 0 rgb(118 126 173 / 34%);
}

.resignationQuestions .radioButton {
    width: 100%;
}

.renunciation {
    position: fixed;
    bottom: 0;
    left: 275px;
    cursor: pointer;
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  border: 0px solid #f3f3f1;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-decoration: none;
}

.react-calendar__navigation__next-button {
  font-size: 30px !important;
}

.react-calendar__navigation__prev-button {
  font-size: 30px !important;
}

.react-calendar__navigation__next2-button{
  display: none;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar button {
  margin: 0;
  border-radius: 8px;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  font-size: 20px;
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  color: black;
  font-weight: bold;
  font-size: 0.8em;
}
/* //remove dots */
.react-calendar__month-view__weekdays__weekday {
  font-size: 14px;
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #18143b;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 2.5vh 0.5em;
  font-size: 16px;
  background: none;
}
.react-calendar__tile:disabled {
  color: #dce4eb;
  background-color: white;
}
/* put border, on hover on click -> #438cd2 */
/*  */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
border: 2px solid #5372e4;
margin: -2px 0 -2px 0;
background-color: white;
transform: scale(.95);
transition-duration: .15s;
transition-property: transform,opacity;
}
.react-calendar__tile--now {
  color: #ffbc42 !important;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #3451b9;
}
.react-calendar__tile--active {
  background: #3451b9;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #3451b9;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
